import React from "react";
import styles from "./arrivalOnDifferentDate.module.scss";
import { infoGrayIcon } from "../../../constant";

export default function ArrivalOnDifferentDate() {
  return (
    <div className={styles.arrivalOnDifferentDateContainer}>
      <img src={infoGrayIcon} alt="info" />
      <p>ARRIVAL ON A DIFFERENT DATE</p>
    </div>
  );
}
