import { iHotelInputPayload } from "../types";
import { toastMessage } from "./ToastProvider";

export function validateHotelBooking(
  adults: number,
  children: number,
  seniors: number,
  rooms: number,
//   cities: iHotelInputPayload[],
) {
  if (rooms <= 0) {
    toastMessage.error("Room count must be greater than zero.");
    return false;
  }
//   if (!cities || cities?.length === 0) {
//     return toastMessage.error("", "Please select a cities to search");
//   }

  const totalPeople = adults + children + seniors;
  if (totalPeople <= 0) {
    toastMessage.error("At least one person must be booked.");
    return false;
  }

  if (totalPeople > rooms * 4) {
    toastMessage.error("Cannot book more than 4 people per room.");
    return false;
  }

  return true;
}
