import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Chip, FormControl, RadioGroup } from "@mui/material";
import {
  backIcon,
  expandIcon,
  ROUTES,
  updatedSeeMoreIcon,
} from "../../constant";
import CustomCheckbox from "../../component/booking/bookingCheckBox/CustomCheckbox";
import FlightListing from "../../component/booking/flightBooking/flightListing/flightListing";
import SelectedOptions from "../../component/booking/selectedOptions/selectedOptions";
import HotelListing from "../../component/booking/hotelBooking/hotelListing/hotelListing";
import FlightSelectButtons from "../../component/booking/flightSelectButton/flightSelectButton";
import BookedSuccessDialog from "../../component/dialog/bookedSuccessDialog/BookedSuccessDialog";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import {
  iGlobalContext,
  iSelectedHotelRoomArr,
} from "../../context/globalContext/interface";
import convertToCustomTime from "../../hooks/flightTimeFormater";
import Loader from "../../component/reusableComponent/loader/Loader";
import NoData from "../../component/booking/noData/noData";
import { searchFlights, searchHotels } from "../../api/booking";
import { format, addDays } from "date-fns";
import styles from "./book.module.scss";
import {
  AirPort,
  BookingDetails,
  FlightPreferences,
  HotelPreferences,
  iCityDetailsResult,
  iHotelInputPayload,
  ItineraryByTimeOfDay,
  ModifiedDataTypes,
  TravelJourney,
} from "../../types";
import DiscardChanges from "../../component/dialog/discardChanges/discardChanges";
import { toastMessage } from "../../helpers/toast/toastMessage";
import HotelInputFields from "../../component/booking/hotelBooking/hotelSingleCityInputFields/hotelInputFields";
import FlightInputFields from "../../component/booking/flightBooking/flightSingleCityInputFields/flightInputFields";
import FlightHotelInputFields from "../../component/booking/flightBooking/flightHotelSingleCityInputFields/flightHotelInputFields";
import BookingLandingPage from "../../component/booking/landingPage/landingPage";
import TravelDetails from "../../component/booking/travelDetails/travelDetails";
import { ScreenTypes } from "../../enums";
import { saveBookInputFields } from "../../api/booking/saveBookedTransactionDetailsAPI";
import { getItineraryDetailsAPI } from "../../api/getItineraryDetails";
import CustomAccordion from "../../component/reusableComponent/customAccordion/CustomAccordion";
import FormatHotelListingDateFormat from "../../utils/formatHotelListingDateFormat";
import FlightHotelSelect from "../../component/booking/flightHotelSelect/flightHotelSelect";
import { isThisHotelRoomSelected } from "../../utils";
import { useResetState } from "../../utils/resetBookingData";
import { TOAST_MESSAGES } from "../../constant/toastMessages";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import { iFlightSearchResponse, iHotelSearchResponse } from "../../interface";
import { validateHotelBooking } from "../../utils/hotelBookingValidation";
// import { getItineraryDetailsAPI } from "../../api/getItineraryDetails";

function Booking(): JSX.Element {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    bookingDetails,
    setBookingDetails,
    setScreen,
    isMobile,
    flightData,
    setFlightData,
    selectedFlightOptions,
    setSelectedFlightOptions,
    selectedChatData,
    setGetHotelRoomsBody,
    hotelData,
    setHotelData,
    selectedHotelRoomArr,
    userDetails,
    setSelectedHotelRoomArr,
    showMore,
    setShowMore,
    setIsFetchingFlights,
    setIsFetchingHotels,
    isFetchingHotels = false,
    prevRoute,
    tripOrItinearyId,
    setSelectedChatData,
    isFetchingFlights,
    setTripOrItinearyId,
    setPrevRoute,
    setHotelPagination,
    hotelPagination,
    userInfo,
    showBookingPreferences,
    setShowBookingPreferences,
  }: iGlobalContext = state;
  const [showDetails, setShowDetails] = useState(false);
  const prevBookingDetailsRef = useRef(
    bookingDetails
      ? (({ visibleList, hotelUniqueId, ...rest }) => rest)(bookingDetails) // Exclude visibleList
      : null
  );

  const [isModified, setIsModified] = useState(false);
  const [loadMoreFlights, setLoadMoreFlights] = useState<boolean>(false);
  const resetState = useResetState();
  const { handleCustomError } = useCustomErrorHandler();
  const isThisMultiCityTrip =
    selectedChatData?.destinationList?.length > 1 || false;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    triggerGAEvent(GA_LABEL_ENUMS.booking_flight_hotel_checkbox_click);

    const { name, checked } = event.target;

    setSelectedFlightOptions((prevState) => {
      // Ensure at least one option is always selected
      if (!checked && Object.values(prevState).filter(Boolean).length === 1) {
        return prevState;
      }

      return {
        ...prevState,
        [name]: checked,
      };
    });
  };
  /**
   * Booked success Dialog
   */
  const [openBookedSuccessDialog, setOpenBookedSuccessDialog] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [loadingCity, setLoadingCity] = useState<string | null>(null);

  let fetchHotelController: any = useRef(null);
  let fetchFlightController: any = useRef(null);

  const handleResetFormDate = async () => {
    await resetState();

    setSelectedHotelRoomArr([]);
    setSelectedFlightOptions({ flights: true, hotels: false });
    handleBackButton();
  };

  const handleSaveBookingInfo = useCallback(async (): Promise<boolean> => {
    const bookFilter = bookingDetails;
    const userId = userDetails?.id || userInfo?.id;
    const status = "draft";
    const metaData: any = {
      selectedChatData: {
        ...selectedChatData,
        prompt: [],
        postText: "",
        preText: "",
      },
    };

    try {
      const response = await saveBookInputFields(
        selectedChatData.id,
        userId,
        metaData,
        bookFilter,
        status
      );
      console.log("Save successful:", response);
      return true;
    } catch (error) {
      console.error("Save failed:", error);
      return false;
    }
  }, [bookingDetails, selectedChatData, userDetails, userInfo]);

  const handleBackButton = async () => {
    setScreen(ScreenTypes.ItineraryInfo);
    // const isValid = await handleSaveBookingInfo();
    // if (!isValid) return;

    if (isMobile) {
      navigate(ROUTES.ItineraryDetails);
      setOpen(false);
      setPrevRoute("");
    }
  };

  const [selectedPackageId, setSelectedPackageId] = useState<number | null>(
    null
  );
  const [selectedHotelId, setSelectedHotelId] = useState<number | null>(null);
  const handleClickOpen = () => {
    bookingDetails.update ? setOpen(true) : handleBackButton();
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handlePackageCostChange = (packageId: number) => {
  //   setSelectedPackageId(packageId);
  // };

  const selectFlightHotel = () => {
    const areAllRoomsSelected = selectedHotelRoomArr.every((eachHotel) => {
      if (!eachHotel?.selectedHotelRoom || !eachHotel?.selectedHotelRoom) {
        // // console.log("entered here");
        // toast.dismiss();
        toastMessage.error(
          `Please select any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      if (
        !eachHotel?.selectedHotelRoom?.preBookId ||
        !eachHotel?.selectedHotelRoom?.sessionId
      ) {
        toastMessage.error(
          `Please selected any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      return true;
    });
    if (!areAllRoomsSelected) return;
    if (!isMobile) {
      setScreen(ScreenTypes.TravellerInfo);
    }
    if (isMobile) navigate(ROUTES.TravelerInformation);
    // navigate(ROUTES.TravelerInformation);
  };

  const generateRoomDetails = (
    roomCount?: number,
    formData?: BookingDetails
  ) => {
    const defaultRoomCount = 1;
    const defaultFormData: BookingDetails = {
      adultsCount: 1,
      childrenCount: 0,
      seniorsCount: 0,
      airportFrom: "",
      airportTo: "",
      travelFromDate: "",
      travelToDate: "",
      routeType: "",
      flightClass: "",
      citiesInput: "",
      cities: [""],
      checkIn: "",
      checkOut: "",
      numberOfRoomCount: 1,
      tripType: selectedChatData?.destinationList.length > 1 ? 3 : 2,
      selectedFlightId: "",
      update: false,
      isFlightAvailable: false,
      airlines: null,
      hotelChains: null,
      flightDayPart: "",
      numberOfStops: "",
      flightRefundable: "",
      noMoreFlights: false,
      hotelUniqueId: "",
      expandedAccordions: [],
      arrCity: "",
      depCity: "",
      searchType: null,
      hideFlights: false,
      cityInfo: {
        departure: "",
        arrival: "",
      },
      isFormUpdated: {
        arrivalForm: false,
        departureForm: false,
      },
      isDataModified: {
        flight: false,
        hotel: false,
      },
      selectedLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      selectedArrivalLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      flightPreferences: {
        connections: 0,
        departureTime: [],
        refundable: false,
        airlines: [],
      },
      hotelPreferences: {
        refundable: false,
        hotelChains: [],
      },
      flightHistory: [
        {
          city_name: "",
          flights: [],
          number_of_days: 0,
        },
      ],
      airPort: [
        {
          arrival: "",
          departure: "",
          fromCity: "",
          destinationCity: "",
          departureDate: "",
          returnDate: "",
          departureAirportFullName: "",
          arrivalAirportFullName: "",
          arrivalCityLatitude: "",
          arrivalCityLongitude: "",
          departureCityLatitude: "",
          departureCityLongitude: "",
          noOfDays: 0,
          newArrivalCitySearch: false,
          newDepartureCitySearch: false,
          arrivalCityDetails: "",
          departureCityDetails: "",
          arrivalSelectedLocation: {
            city: "",
            state: "",
            country: "",
            latitude: 0,
            longitude: 0,
          },
          departureSelectedLocation: {
            city: "",
            state: "",
            country: "",
            latitude: 0,
            longitude: 0,
          },
        },
      ],
      travelJourneyData: [
        {
          departureCityAddress: "",
          arrivalCityAddress: "",
          departureDate: "",
          returnDate: "",
          days: [],
          flightCheckIn: "",
          flightCheckOut: "",
          totalDays: 0,
        },
      ],
      visibleList: {
        flight: 0,
        hotel: 0,
        package: 2,
      },
      filters: {
        starRating: [3],
      },
    };
    const finalRoomCount = roomCount ?? defaultRoomCount;
    const finalFormData = formData ?? defaultFormData;
    const rooms = [];
    for (let i = 0; i < finalRoomCount; i++) {
      let room = {
        roomNo: i + 1,
        adults: finalFormData.adultsCount,
        child: finalFormData.childrenCount,
        seniors: finalFormData.seniorsCount,
      };

      let roomWithChildAges;
      if (finalFormData.childrenCount > 0) {
        let childAges = Array(finalFormData.childrenCount).fill(10).join(", ");
        roomWithChildAges = { ...room, childAges: childAges };
      } else {
        roomWithChildAges = room;
      }

      rooms.push(roomWithChildAges);
    }

    return rooms;
  };

  const handleHotelSelect = (
    hotelId: number,
    checkIn: string,
    checkOut: string,
    city: string
  ) => {
    const getHotelRoomsBody = {
      hotelId: hotelId,
      checkIn,
      checkOut,
      currency: "USD",
      noOfRooms: bookingDetails.numberOfRoomCount,
      pagination: {
        offset: 0,
        count: 1000,
      },
      city,
      roomInfo: generateRoomDetails(
        bookingDetails.numberOfRoomCount,
        bookingDetails
      ),
      // roomInfo: Array.from(
      //   { length: bookingDetails.numberOfRoomCount },
      //   (_obj, idx) => {
      //     return {
      //       roomNo: idx + 1,
      //       adults: bookingDetails.adultsCount,
      //       child: bookingDetails.childrenCount,
      //       seniors: bookingDetails.seniorsCount,
      //     };
      //   }
      // ),
    };
    setGetHotelRoomsBody(getHotelRoomsBody);
    setSelectedHotelId(hotelId);
  };

  const [loading, setLoading] = useState<boolean>(false);

  const simplifyFlights = (flights: AirPort[]) => {
    return flights.map((flight) => {
      const simplifiedFlight = {
        arrival: flight.arrival,
        departure: flight.departure,
        departureDate: flight.departureDate,
      };

      if (bookingDetails.tripType !== 3 && flight.returnDate) {
        return { ...simplifiedFlight, returnDate: flight.returnDate };
      }

      return simplifiedFlight;
    });
  };

  const modifyFlightTripData = (
    travelFromDate: string | undefined | null,
    travelToDate: string | undefined | null,
    TripData: iCityDetailsResult[]
  ): ModifiedDataTypes[] => {
    const modifiedData: ModifiedDataTypes[] = [];

    if (
      !travelFromDate ||
      !travelToDate ||
      isNaN(new Date(travelFromDate).getTime()) ||
      isNaN(new Date(travelToDate).getTime())
    ) {
      return modifiedData;
    }

    let currentDate = new Date(travelFromDate + "T00:00:00");
    const endDate = new Date(travelToDate + "T00:00:00");
    TripData?.forEach((trip) => {
      if (!trip.tripDetails || !Array.isArray(trip.tripDetails)) return;

      trip.tripDetails.forEach((detail) => {
        if (currentDate > endDate) return;

        const checkIn = format(currentDate, "yyyy-MM-dd");
        currentDate = addDays(currentDate, 1);
        const checkOut = format(
          currentDate > endDate ? endDate : currentDate,
          "yyyy-MM-dd"
        );

        // Get the earliest and latest activities
        const sortedActivities = detail.itinerary_by_time_of_the_day.sort(
          (a: ItineraryByTimeOfDay, b: ItineraryByTimeOfDay) => {
            const order = ["morning", "afternoon", "evening", "night"];
            return (
              order.indexOf(a.time_of_the_day.toLowerCase()) -
              order.indexOf(b.time_of_the_day.toLowerCase())
            );
          }
        );

        const morningActivity = sortedActivities[0]; // Earliest activity
        const eveningActivity = sortedActivities[sortedActivities.length - 1]; // Latest activity

        modifiedData.push({
          checkIn,
          checkOut,
          country: trip.city_country_code,
          city: trip.city_name,
          state: trip.city_state,
          countryFullName: trip.city_country || trip.destination_country || "",
          latitude: trip.city_latitude,
          longitude: trip.city_longitude,
          checkInActivityType: morningActivity?.activity_type || "",
          checkOutActivityType: eveningActivity?.activity_type || "",
        });
      });
    });

    return modifiedData;
  };

  const modifiedData = modifyFlightTripData(
    bookingDetails.travelFromDate,
    bookingDetails.travelToDate,
    selectedChatData.destinationList
  );
  const groupConsecutiveStays = (
    data: ModifiedDataTypes[]
  ): ModifiedDataTypes[] => {
    const result: ModifiedDataTypes[] = [];

    if (!data || data?.length === 0) {
      console.warn("Input data is undefined, null, or empty.");
      return result;
    }

    data.forEach((currentStay, index) => {
      if (
        !currentStay.city ||
        // !currentStay.country ||
        !currentStay.checkIn ||
        !currentStay.checkOut
      ) {
        console.error(
          `Invalid data at index ${index}: ${JSON.stringify(currentStay)}`
        );
        return;
      }

      const lastEntry = result[result.length - 1];

      if (
        lastEntry &&
        lastEntry.city === currentStay.city &&
        lastEntry.country === currentStay.country &&
        lastEntry.checkOut === currentStay.checkIn
      ) {
        lastEntry.checkOut = currentStay.checkOut;
      } else {
        result.push({ ...currentStay });
      }
    });

    return result;
  };

  const HandleHotelCityPayload = (input: ModifiedDataTypes[]) => {
    return input.map((userInputs: ModifiedDataTypes) => ({
      checkIn: userInputs.checkIn,
      checkOut: userInputs.checkOut,
      country: userInputs.country,
      city: userInputs.city,
      latitude: userInputs.latitude,
      longitude: userInputs.longitude,
    }));
  };

  const hotelCityPayload = HandleHotelCityPayload(
    groupConsecutiveStays(modifiedData)
  );
  const handleFlightSearch = async (count: number) => {
    setShowDetails(true);
    const flights = simplifyFlights(bookingDetails?.airPort);
    const missingFields = flights?.some(
      (flight) => !flight.arrival || !flight.departure
    );
    if (missingFields) {
      return toastMessage.error(
        "",
        "Please select both arrival and departure airports"
      );
    }
    const sameAirports = flights?.some(
      (flight) => flight.arrival === flight.departure
    );
    if (sameAirports) {
      setShowDetails(false);
      return toastMessage.error(
        "",
        "Arrival and departure airports cannot be the same"
      );
    }
    if (count < 4) {
      setLoading(true);
      setLoadMoreFlights(false);
    }
    const requestBody = {
      userId: userDetails?.id || userInfo?.id,
      pagination: {
        offset: count,
        count: 4,
      },
      adults: bookingDetails.adultsCount,
      children: bookingDetails.childrenCount,
      infants: 0,
      seniors: bookingDetails.seniorsCount,
      tripType: bookingDetails.tripType,
      cabin: bookingDetails.flightClass,
      currency: "USD",
      segments: simplifyFlights(bookingDetails.airPort),
      filters: {
        airlineInclude:
          bookingDetails.flightPreferences?.airlines?.join(", ") || null,
        refundable: bookingDetails?.flightPreferences?.refundable || false,
        // daypart: flightTimes[0],
        daypart:
          bookingDetails?.flightPreferences?.departureTime?.join(", ") || null,
        stopsCount: bookingDetails?.flightPreferences?.connections || 0,
      },
    };

    try {
      if (count === 0) setIsFetchingFlights(true);

      fetchFlightController.current = new AbortController();
      const FlightData: iFlightSearchResponse = await searchFlights(
        requestBody,
        fetchFlightController.current
      );
      const {
        visibleList,
        hotelUniqueId: _,
        ...detailsWithoutVisibleList
      } = bookingDetails || {};
      prevBookingDetailsRef.current = detailsWithoutVisibleList;
      setIsModified(true);
      if (FlightData?.statusCode !== 200) {
        // toastMessage.error(
        //   TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.messageTitle,
        //   TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.message
        // );
        handleCustomError(FlightData?.statusCode, "flight.search");
        return null;
      }
      const newSectors = FlightData?.data?.sectors || [];
      if (newSectors.length === 0) {
        setLoadMoreFlights(false);
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.NoFlightsFound.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.NoFlightsFound.message
        );
        return;
      }
      setFlightData((prevData: any) => {
        const prevSectors = prevData?.sectors || [];
        if (newSectors.length < 4) {
          setBookingDetails((prevData) => ({
            ...prevData,
            noMoreFlights: true,
          }));
        }
        return {
          ...prevData,
          sectors: [...prevSectors, ...newSectors],
          encryptedKey: FlightData?.data?.encryptedKey,
          pagination: FlightData?.data?.pagination,
        };
      });
      if (count === 0) setIsFetchingFlights(false);
      setShowMore((prevShowMore) => ({
        ...prevShowMore,
        visibleList: {
          ...prevShowMore.visibleList,
          flight: count,
        },
      }));
    } catch (err) {
      if (err instanceof Error) {
        if (
          err?.name !== "AbortError" &&
          !err.message.includes("signal is aborted")
        ) {
          toastMessage.error(
            TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.messageTitle,
            TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.message
          );
        }
      } else {
        console.error("An unexpected error occurred:", err);
      }
      if (count === 0) setIsFetchingFlights(false);
      setLoadMoreFlights(false);
      setFlightData(null);
      // toastMessage.error(
      //   TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.messageTitle,
      //   TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.message
      // );
    } finally {
      if (count === 0) setIsFetchingFlights(false);
      setLoading(false);
      setLoadMoreFlights(false);
    }
  };

  function arrayToString(arr: number[] | null | undefined): string {
    if (!arr || arr.length === 0) {
      console.warn("Input data is null, undefined, or empty.");
      return "";
    }

    return arr.join(",");
  }

  const handleHotelSearch = async (
    count: number,
    cities: iHotelInputPayload[],
    isFetchingFreshly: boolean = false,
    hotelUniqueId?: string
  ) => {
    if (
      !validateHotelBooking(
        bookingDetails.adultsCount,
        bookingDetails.childrenCount,
        bookingDetails.seniorsCount,
        bookingDetails.numberOfRoomCount
        // cities
      )
    ) {
      setLoading(false);
      return;
    }
    // if (!cities || cities?.length === 0) {
    //   return toastMessage.error("", "Please select a cities to search");
    // }

    if (bookingDetails?.citiesInput === "") {
      return toastMessage.error("", "Please select a cities to search");
    }

    setShowDetails(true);
    const newCities = bookingDetails.citiesInput
      .split(",")
      .map((city) => city.trim())
      .filter((city) => city !== "");
    // const hoteRatings = bookingDetails.filters[0]
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      cities: newCities,
    }));
    const requestBody = {
      userId: userDetails?.id || userInfo?.id,
      pagination: {
        offset: count,
        count: 4,
        uniqueId: hotelUniqueId,
      },
      // cities: groupConsecutiveStays(modifiedData),
      cities: cities,
      currency: "USD",
      noOfRooms: bookingDetails.numberOfRoomCount,
      roomInfo: generateRoomDetails(
        bookingDetails.numberOfRoomCount,
        bookingDetails
      ),
      filters: {
        hotelChain:
          bookingDetails?.hotelPreferences?.hotelChains?.toString() || "",
        starRating: arrayToString(bookingDetails?.filters?.starRating),
        refundable: bookingDetails?.hotelPreferences?.refundable || false,
      },
    };
    const {
      visibleList,
      hotelUniqueId: _,
      ...detailsWithoutVisibleList
    } = bookingDetails || {};
    prevBookingDetailsRef.current = detailsWithoutVisibleList;
    try {
      if (isFetchingFreshly) setIsFetchingHotels(true);
      fetchHotelController.current = new AbortController();
      const response: iHotelSearchResponse = await searchHotels(
        requestBody,
        fetchHotelController.current
      );
      setIsModified(true);
      if (response.statusCode !== 200) {
        // toastMessage.error(
        //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
        //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
        // );
        handleCustomError(response?.statusCode, "hotel.search");
        return null;
      }
      if (response?.data?.pagination) {
        setBookingDetails((prevBookingDetails) => ({
          ...prevBookingDetails,
          hotelUniqueId: response?.data?.pagination.uniqueId || "",
          visibleList: {
            ...showMore.visibleList,
            hotel: response?.data?.pagination.offset,
          },
        }));
      }

      if (response?.data?.data?.length > 0) {
        const updateReceivedData = Array?.isArray(response?.data)
          ? response?.data
          : response?.data?.data;
        setHotelData((prevHotelData: any) => {
          if (!prevHotelData || !prevHotelData.data) {
            // Initialize if no previous data
            return { data: updateReceivedData };
          }

          // Append new data without replacing old data
          const updatedHotelData = [...prevHotelData.data];

          updateReceivedData.forEach((newItemHotel: any) => {
            const existingHotelIndex = updatedHotelData?.findIndex(
              (itemHotel: any) =>
                itemHotel.city === newItemHotel?.city &&
                itemHotel.checkIn === newItemHotel?.checkIn &&
                itemHotel.checkOut === newItemHotel?.checkOut
            );

            if (existingHotelIndex > -1) {
              // If the city and check-in/out match, append the hotels to the existing entry
              updatedHotelData[existingHotelIndex] = {
                ...updatedHotelData[existingHotelIndex],
                hotels: [
                  ...updatedHotelData[existingHotelIndex].hotels,
                  ...newItemHotel.hotels, // Append new hotels
                ],
              };
            } else {
              // If city/check-in/out doesn't exist, add the new entry
              updatedHotelData.push(newItemHotel);
            }
          });

          return {
            ...prevHotelData,
            data: updatedHotelData, // Return updated data with new hotels appended
          };
        });

        // const selectedHotelRoomSet =
        //   selectedHotelRoomArr?.map((item: any) => ({
        //     checkIn: item?.checkIn,
        //     checkOut: item?.checkOut,
        //     city: item?.city,
        //   })) || [];

        // const fetchedHotelArgSet =
        //   response?.data?.map((item: any) => ({
        //     checkIn: item?.checkIn,
        //     checkOut: item?.checkOut,
        //     city: item?.city,
        //   })) || [];
        // // console.log(
        //   selectedHotelRoomArr,
        //   selectedHotelRoomSet,
        //   fetchedHotelArgSet,
        //   areTwoArrObjectsEqual(selectedHotelRoomSet, fetchedHotelArgSet),
        //   "testing"
        // );

        if (
          // !areTwoArrObjectsEqual(selectedHotelRoomSet, fetchedHotelArgSet) &&
          isFetchingFreshly
          // &&          isThisAMulticityTrip
        ) {
          setSelectedHotelRoomArr(
            response?.data?.data?.map(
              (item: any): iSelectedHotelRoomArr => ({
                checkIn: item?.checkIn,
                checkOut: item?.checkOut,
                city: item?.city,
                selectedHotelRoom: null,
              })
            )
          );
          setIsFetchingHotels(false);
        }
        setShowMore((prevShowMore) => ({
          ...prevShowMore,
          visibleList: {
            ...prevShowMore?.visibleList,
            hotel: count,
          },
        }));

        // setLoadMore(false);
      } else {
        if (isFetchingFreshly) setIsFetchingHotels(false);
        console.warn("No new hotels found");
        setLoadMore(false);
      }
    } catch (err) {
      if (err instanceof Error) {
        if (
          err?.name !== "AbortError" &&
          !err.message.includes("signal is aborted")
        ) {
          toastMessage.error(
            TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
            TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
          );
        }
      } else {
        console.error("An unexpected error occurred:", err);
      }
      if (isFetchingFreshly) setIsFetchingHotels(false);
      setHotelData(null);
      // toastMessage.error(
      //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
      //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
      // );
      setLoadMore(false);
    } finally {
      if (isFetchingFreshly) setIsFetchingHotels(false);
      setLoading(false);
      setLoadMore(false);
    }
  };

  function addOffsetData(data: any) {
    return data.map((city: any) => {
      return {
        ...city,
        offset: 0,
        hasMoreHotels: true,
      };
    });
  }
  const hotelSearch = (isFetchingFreshly: boolean = false) => {
    const cities = hotelCityPayload;
    setHotelPagination(addOffsetData(cities));
    setLoading(true);
    setLoadMore(false);
    setTimeout(() => {
      handleHotelSearch(0, cities, isFetchingFreshly);
    }, 10);
  };

  useEffect(() => {
    if (!isFetchingHotels) {
      fetchHotelController?.current?.abort();
    }
    if (!isFetchingFlights) {
      fetchFlightController?.current?.abort();
    }
  }, [isFetchingHotels, isFetchingFlights]);

  const handleShowMoreFlights = () => {
    setLoadMoreFlights(true);

    setBookingDetails((prevDetails) => {
      const newFlightCount = prevDetails?.visibleList?.flight + 4;

      setTimeout(() => {
        handleFlightSearch(newFlightCount);
      }, 10);

      return {
        ...prevDetails,
        visibleList: {
          ...prevDetails.visibleList,
          flight: newFlightCount,
        },
      };
    });
  };

  const handleFlightSearchClick = () => {
    setFlightData([]);
    setBookingDetails((prevDetails) => {
      return {
        ...prevDetails,
        noMoreFlights: false,
        visibleList: {
          ...prevDetails.visibleList,
          flight: 0,
        },
      };
    });
    handleFlightSearch(0);
  };

  const handleSearchClick = (isFetchingFreshly: boolean = false) => {
    setHotelData([]);
    setBookingDetails((prevDetails) => {
      return {
        ...prevDetails,
        visibleList: {
          ...prevDetails.visibleList,
          hotel: 4,
        },
      };
    });
    hotelSearch(isFetchingFreshly);
  };

  const handleSingleCityShowMoreHotels = () => {
    setLoading(false);
    setLoadMore(true);
    setBookingDetails((prevDetails) => {
      const newHotelCount = prevDetails?.visibleList?.hotel + 4;
      const cities = hotelCityPayload;
      setTimeout(() => {
        handleHotelSearch(newHotelCount, cities, false);
      }, 10);
      return {
        ...prevDetails,
        visibleList: {
          ...prevDetails.visibleList,
          hotel: newHotelCount,
        },
      };
    });
  };

  const handleShowMoreHotels = (city: string) => {
    setLoading(false);
    setLoadMore(true);
    setLoadingCity(city);
    // offset update
    setHotelPagination((prevPagination) => {
      return prevPagination.map((hotel) => {
        if (hotel.city === city) {
          const currentCityData = hotelData?.data.find(
            (c: any) => c.city === city
          );
          const totalHotels = currentCityData?.hotels.length || 0;
          const nextOffset = hotel.offset + 4;
          const hasMore = nextOffset <= totalHotels;
          return {
            ...hotel,
            offset: hotel.offset + 4,
            hasMoreHotels: hasMore,
          };
        }
        return hotel; // other city entries unchanged
      });
    });
  };
  const updatedCity = hotelPagination.find(
    (hotel) => hotel.city === loadingCity
  );

  useEffect(() => {
    const isUsingMobile = window.matchMedia("(max-width: 768px)").matches;
    if (
      Object?.keys(selectedChatData)?.length === 0 &&
      !hotelData &&
      !flightData &&
      isUsingMobile
    ) {
      navigate(ROUTES.Home, { replace: true });
    }
    if (!hotelData || hotelData.length === 0) {
      return;
    }
    const updatedCity = hotelPagination.find(
      (hotel) => hotel?.city === loadingCity
    );

    if (updatedCity) {
      const cityHotels = hotelPagination
        .filter((hotel) => hotel?.city === loadingCity)
        .map(
          ({ offset, hasMoreHotels, ...hotelWithoutOffset }) =>
            hotelWithoutOffset
        );

      handleHotelSearch(
        updatedCity.offset,
        cityHotels,
        false,
        bookingDetails.hotelUniqueId
      );
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        visibleList: {
          ...prevDetails.visibleList,
          hotel: updatedCity.offset,
        },
      }));
    }
  }, [loadingCity, updatedCity?.offset]);

  const handleFlightBook = () => {
    localStorage.removeItem("_traveler_info_array_state");
    localStorage.removeItem("_traveler_info_array");
    isMobile
      ? navigate(ROUTES.TravelerInformation)
      : setScreen(ScreenTypes.TravellerInfo);
  };
  const [isSaving, setIsSaving] = useState<boolean>(false);
  useEffect(() => {
    const prevBookingDetails = prevBookingDetailsRef.current;
    if (
      !isSaving &&
      JSON.stringify(prevBookingDetails) !== JSON.stringify(bookingDetails)
    ) {
      setIsSaving(true);
      handleSaveBookingInfo()
        .then(() => {
          setIsSaving(false);
        })
        .catch((error) => {
          console.error("Error saving booking info:", error);
          setIsSaving(false);
        });
    }

    prevBookingDetailsRef.current = bookingDetails;
  }, [bookingDetails, handleSaveBookingInfo, isSaving]);

  const getItineraryDetails = async () => {
    let itineraryId =
      prevRoute === ROUTES.MyTrips ? tripOrItinearyId : selectedChatData.id;
    if (!itineraryId) return;
    if (prevRoute === ROUTES.MyTrips) setTripOrItinearyId("");
    const response = await getItineraryDetailsAPI(itineraryId);
    if (response?.statusCode !== 200) {
      handleCustomError(response?.statusCode, "itinerary.details");
      return;
    }
    const metaData =
      typeof response.data.metaData === "string"
        ? JSON.parse(response.data.metaData)
        : response.data.metaData;
    const chatData = metaData?.[0]?.selectedChatData;
    if (chatData) setSelectedChatData(chatData);
    const updatedBookingDetails = {
      ...bookingDetails,
      ...response.bookFilter,
      airPort: response.bookFilter?.airPort
        ? bookingDetails.airPort.map((airport, index) => ({
            ...airport,
            ...response.bookFilter.airPort[index],
          }))
        : bookingDetails.airPort,
    };
    setBookingDetails(updatedBookingDetails);
  };

  useEffect(() => {
    getItineraryDetails();
  }, [tripOrItinearyId]);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      selectedFlightId: event.target.value,
    }));
  };
  const handleToggleAccordion = (index: number) => {
    setBookingDetails((prevDetails) => {
      const expandedAccordions = prevDetails?.expandedAccordions || [];
      const isExpanded = expandedAccordions?.includes(index);
      const newExpanded = isExpanded
        ? expandedAccordions?.filter((i: number) => i !== index)
        : [...expandedAccordions, index];

      return {
        ...prevDetails,
        expandedAccordions: newExpanded,
      };
    });
  };

  function getPreferenceCount(
    flightData: FlightPreferences | null | undefined,
    hotelData: HotelPreferences | null | undefined,
    selectedFlightOptions:
      | { flights: boolean; hotels: boolean }
      | null
      | undefined
  ): number {
    if (!flightData || !hotelData || !selectedFlightOptions) {
      console.error(
        "Invalid input: flightData, hotelData, or selectedFlightOptions is null or undefined."
      );
      return 0;
    }

    let totalCount = 0;

    if (selectedFlightOptions.flights) {
      totalCount += 0;
      totalCount += flightData.connections >= 1 ? 1 : 0;
      totalCount += flightData.departureTime?.length || 0;
      totalCount += flightData.airlines?.length || 0;
      if (flightData.refundable === true) {
        totalCount += 1;
      }
    }

    if (selectedFlightOptions.hotels) {
      totalCount += 0;
      totalCount += hotelData.hotelChains?.length || 0;
      if (hotelData.refundable) {
        totalCount += 1;
      }
    }

    return totalCount;
  }

  const transformFlightData = (data: any) => {
    const generateDays = (numDays: any) => {
      return Array.from({ length: numDays }, (_, i) => `Day ${i + 1}`);
    };
    return data.reduce((acc: any, city: any) => {
      const { city_name, number_of_days, flights } = city;
      if (flights.length > 0) {
        flights.forEach((flight: any) => {
          acc.push({
            departureCityAddress: flight.departure_city_name,
            departureCountryCode: flight.departure_city_country_code,
            departureLatitude: flight.departure_city_latitude,
            departureLongitude: flight.departure_city_longitude,
            arrivalCityAddress: flight.arrival_city_name,
            arrivalCityCountryCode: flight.arrival_city_country_code,
            arrivalLatitude: flight.arrival_city_latitude,
            arrivalLongitude: flight.arrival_city_longitude,
            departureDate: "",
            returnDate: "",
            days: generateDays(number_of_days),
            flightCheckIn: "",
            flightCheckOut: "",
            totalDays: number_of_days,
          });
        });
      }

      return acc;
    }, []);
  };

  const transformedData = transformFlightData(bookingDetails.flightHistory);
  function mapDates(
    startDate: string,
    data: any[],
    setBookingDetails: (updateFunc: (prevData: any) => any) => void
  ) {
    try {
      if (
        !startDate ||
        typeof startDate !== "string" ||
        startDate.trim() === ""
      ) {
        console.log(
          "Invalid or missing startDate. It must be a non-empty string in YYYY-MM-DD format."
        );
        return;
      }

      const start = new Date(startDate);
      if (isNaN(start.getTime())) {
        console.log(
          `Invalid startDate format: ${startDate}. Expected format: YYYY-MM-DD`
        );
        return;
      }
      if (!Array.isArray(data)) {
        console.log("Invalid data. It must be an array.");
        return;
      }
      if (data.length === 0) {
        console.log("Data array is empty.");
        return;
      }

      data.forEach((segment, index) => {
        if (!segment || typeof segment !== "object") {
          console.log(
            `Invalid segment at index ${index}. Each segment must be an object.`
          );
        }

        if (typeof segment.totalDays !== "number" || segment.totalDays < 0) {
          console.log(
            `Invalid or missing totalDays for segment at index ${index}: ${segment.totalDays}`
          );
        }
      });

      const updatedData = data.map((segment, index) => {
        const departureDate = new Date(start);

        // Calculate return date based on segment totalDays
        const returnDate = new Date(start);
        returnDate.setDate(returnDate.getDate() + segment.totalDays - 1);

        // Update start date for next segment only if it's not the last segment
        if (index < data.length - 1) {
          start.setDate(start.getDate() + segment.totalDays);
        }

        return {
          ...segment,
          departureDate: departureDate.toISOString().split("T")[0],
          returnDate: returnDate.toISOString().split("T")[0],
        };
      });

      setBookingDetails((prevData: any) => ({
        ...prevData,
        travelJourneyData: updatedData,
      }));
    } catch (error) {
      console.error("Error in mapDates:", error);
    }
  }

  useEffect(() => {
    if (bookingDetails.travelFromDate) {
      mapDates(
        bookingDetails.travelFromDate,
        transformedData,
        setBookingDetails
      );
    }
  }, [bookingDetails.travelFromDate]);

  // console.log("prev info", prevBookingDetailsRef.current);
  const { visibleList, hotelUniqueId, ...currentDetailsWithoutVisibleList } =
    bookingDetails || {};
  // console.log("current info", currentDetailsWithoutVisibleList);

  const handleBookingDetailsChange = () => {
    const { visibleList, hotelUniqueId, ...currentDetailsWithoutVisibleList } =
      bookingDetails || {};
    const { ...prevDetailsWithoutVisibleList } =
      prevBookingDetailsRef.current || {};
    if (
      isModified &&
      JSON.stringify(currentDetailsWithoutVisibleList) !==
        JSON.stringify(prevDetailsWithoutVisibleList)
    ) {
      // toastMessage.error(
      //   "Don't forget to click 'Search' to see results based on your new filters"
      // );
      // setFlightData(null);
      // setHotelData([]);
      setIsModified(false);
      setBookingDetails((prevData) => ({
        ...prevData,
        isDataModified: {
          ...prevData.isDataModified,
          flight: true,
          hotel: true,
        },
      }));
    }
  };

  useEffect(() => {
    handleBookingDetailsChange();
  }, [bookingDetails, isModified]);

  return (
    <div className={styles.main}>
      <BookedSuccessDialog
        open={openBookedSuccessDialog}
        onClose={() => {
          setOpenBookedSuccessDialog(false);
        }}
      />
      <DiscardChanges
        open={open}
        onClose={handleClose}
        handleDiscard={handleResetFormDate}
        message="Discarding changes will result in losing your modifications"
      />
      <div className={styles.container}>
        <header className={styles.header}>
          <Button
            className={`${styles.columnAlignButton}`}
            // onClick={handleBackButton}
            onClick={handleClickOpen}>
            <img src={backIcon} alt="Close" />
            <p>BACK TO ITINERARY</p>
          </Button>
          <p className={styles.bookingTitle}>Search Flights & Hotels </p>
        </header>
        {showDetails ? (
          <TravelDetails
            handleShowDetails={() => setShowDetails(false)}
            bookingDetails={bookingDetails}
            inputFieldData={
              modifiedData ? groupConsecutiveStays(modifiedData) : []
            }
            selectedOptions={selectedFlightOptions}
          />
        ) : (
          <div className={styles.inputBox}>
            <div
              className={`${styles.inputHeader} ${styles.collapseContainer}`}>
              <section
                className={styles.expandIconBox}
                onClick={() => {
                  triggerGAEvent(
                    GA_LABEL_ENUMS.booking_collapse_accordion_click
                  );
                }}>
                <div
                  className={styles.showDetailsBtn}
                  onClick={() => setShowDetails(true)}>
                  <p className={styles.showBtnTitle}>Collapse</p>
                  <img src={expandIcon} alt="" className={styles.expandIcon} />
                </div>
              </section>
            </div>
            <div className={styles.inputHeader}>
              <section className={styles.checkBox}>
                <CustomCheckbox
                  label="Flights"
                  name="flights"
                  handleCheckboxChange={handleChange}
                  selectedOptions={selectedFlightOptions}
                  onClick={() => {
                    triggerGAEvent(GA_LABEL_ENUMS.booking_back_click);
                    if (bookingDetails.hideFlights)
                      toastMessage.error(
                        "You do not have flights for this itinerary"
                      );
                  }}
                  disable={bookingDetails.hideFlights}
                />
                <CustomCheckbox
                  label="Hotels"
                  name="hotels"
                  handleCheckboxChange={handleChange}
                  selectedOptions={selectedFlightOptions}
                  onClick={() => {
                    triggerGAEvent(GA_LABEL_ENUMS.booking_hotel_checkbox_click);
                  }}
                />
              </section>
              <section
                className={styles.expandIconBox}
                onClick={() => {
                  triggerGAEvent(
                    GA_LABEL_ENUMS.booking_collapse_accordion_click
                  );
                }}>
                <div className={styles.showDetailsBtn}>
                  <Chip
                    label={`Preferences (${getPreferenceCount(
                      bookingDetails.flightPreferences,
                      bookingDetails.hotelPreferences,
                      selectedFlightOptions
                    )})`}
                    size="medium"
                    variant="outlined"
                    sx={{
                      height: "20px",
                    }}
                    onClick={() => {
                      setShowBookingPreferences(true);
                    }}
                    className={styles.chip}
                    style={{
                      backgroundColor: showBookingPreferences
                        ? "#A4D1F0"
                        : "#ffff",
                    }}
                  />
                </div>
              </section>
            </div>
            {selectedFlightOptions.flights && !selectedFlightOptions.hotels && (
              <FlightInputFields
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                searchFlights={handleFlightSearchClick}
                getTravelData={() =>
                  mapDates(
                    bookingDetails.travelFromDate,
                    transformedData,
                    setBookingDetails
                  )
                }
              />
            )}
            {!selectedFlightOptions.flights && selectedFlightOptions.hotels && (
              <HotelInputFields
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                searchHotels={() => {
                  setSelectedHotelRoomArr([]);
                  handleSearchClick(true);
                }}
                inputFieldData={
                  modifiedData ? groupConsecutiveStays(modifiedData) : []
                }
              />
            )}
            {selectedFlightOptions.flights && selectedFlightOptions.hotels && (
              <FlightHotelInputFields
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                searchFlights={handleFlightSearchClick}
                searchHotels={() => {
                  setSelectedHotelRoomArr([]);
                  setHotelData([]);
                  hotelSearch(true);
                }}
                getTravelData={() =>
                  mapDates(
                    bookingDetails.travelFromDate,
                    transformedData,
                    setBookingDetails
                  )
                }
              />
            )}
          </div>
        )}

        {/* Flight listing */}

        {selectedFlightOptions.flights && !selectedFlightOptions.hotels && (
          <section className={styles.list}>
            {/* {flightData?.sectors && (
              <section className={styles.selected}>
                <SelectedOptions
                  selectedOptions={selectedFlightOptions}
                  flightData={flightData?.sectors || "Flight"}
                />
              </section>
            )} */}
            {flightData && (
              <section className={styles.selected}>
                <SelectedOptions
                  selectedOptions={selectedFlightOptions}
                  flightData={flightData?.sectors || "Flight"}
                />
              </section>
            )}

            <section className={styles.listCard}>
              <FormControl className={styles.radioControl}>
                <RadioGroup
                  onChange={handleSelect}
                  value={bookingDetails.selectedFlightId}
                  className={styles.radioGroup}>
                  {flightData?.sectors
                    // ?.slice(0, bookingDetails.visibleList.flight)
                    ?.filter(
                      (flight: any, index: number, self: any[]) =>
                        index ===
                        self.findIndex((f) => f.uniqueId === flight.uniqueId)
                    )
                    ?.map((flight: any, index: number) => {
                      const outboundFlight = flight.outbound?.[0];
                      if (!outboundFlight) return null;

                      const {
                        departureDate,
                        arrivalDate,
                        airlineName,
                        airlineImage,
                        stops,
                        stopsCount,
                        route,
                      } = outboundFlight;

                      return (
                        <div key={index}>
                          <FlightListing
                            selectedData={bookingDetails.selectedFlightId}
                            handleSelect={handleSelect}
                            setFlightData={setFlightData}
                            flightData={flightData}
                            key={index}
                            startTime={
                              departureDate &&
                              convertToCustomTime(departureDate)
                            }
                            endTime={
                              arrivalDate && convertToCustomTime(arrivalDate)
                            }
                            flightName={airlineName}
                            flightImage={airlineImage}
                            stopsInfo={
                              stops
                                ?.map((stop: any) => stop.airport)
                                .join(", ") || ""
                            }
                            stops={stopsCount}
                            stopTime={stops?.[0]?.layover || ""}
                            airports={route}
                            flightCost={flight?.price}
                            flightClass={bookingDetails.flightClass}
                            tourPackage={false}
                            buttonName="Book"
                            sup="1"
                            detailsButton="Flight Details"
                            handleBook={handleFlightBook}
                            encryptedKey={flightData?.encryptedKey}
                            uniqueId={flight?.uniqueId}
                            inOut="outbound"
                          />
                        </div>
                      );
                    })}
                </RadioGroup>
              </FormControl>

              {flightData?.sectors && (
                <>
                  {bookingDetails.noMoreFlights ? null : (
                    <div className={styles.showCard}>
                      {loadMoreFlights ? (
                        <Loader />
                      ) : (
                        <Button
                          className={styles.showMoreBtn}
                          endIcon={
                            <img
                              src={updatedSeeMoreIcon}
                              alt="btn"
                              className={styles.showMoreIcon}
                            />
                          }
                          onClick={handleShowMoreFlights}>
                          Show More
                        </Button>
                      )}
                    </div>
                  )}
                </>
              )}
            </section>
            {/* {!loading && (
            )} */}
            {flightData?.sectors && flightData?.sectors.length === 0 && (
              <NoData
                message={
                  TOAST_MESSAGES.BOOKING.Search.NoFlightsFoundScreenMessage
                    .message
                }
              />
            )}
            {flightData && flightData.length === 0 && (
              <>
                {loading ? null : (
                  <NoData
                    message={
                      TOAST_MESSAGES.BOOKING.Search.NoFlightsFoundScreenMessage
                        .message
                    }
                  />
                )}
              </>
            )}
          </section>
        )}

        {/* Hotel listing */}

        {selectedFlightOptions.hotels && !selectedFlightOptions.flights && (
          <section className={styles.list}>
            {/* {hotelData && hotelData?.data && ( */}
            {hotelData?.data && (
              <section className={styles.selected}>
                <SelectedOptions selectedOptions={selectedFlightOptions} />
              </section>
            )}
            {/* )} */}
            {!loading && (
              <>
                {isThisMultiCityTrip ? (
                  <section className={styles.listCard}>
                    {hotelData &&
                      hotelData?.data?.map(
                        (cityData: any, cityIndex: number) => {
                          if (!cityData?.hotels?.length) {
                            return (
                              <div
                                key={cityIndex}
                                className={styles.listCardContainer}>
                                <p className={styles.noHotelsMessage}>
                                  No hotels found in {cityData?.city}
                                </p>
                              </div>
                            );
                          }

                          return (
                            <div
                              key={cityIndex}
                              className={styles.listCardContainer}>
                              <CustomAccordion
                                className={styles.customFlightStyles}
                                summaryClass={styles.summaryClass}
                                accordianDetailsClass={
                                  styles.accordFlightDetailsClass
                                }
                                // defaultExpanded={cityIndex === 0}
                                defaultExpanded={
                                  bookingDetails?.expandedAccordions?.includes(
                                    cityIndex
                                  ) || false
                                }
                                onChange={() =>
                                  handleToggleAccordion(cityIndex)
                                }
                                hideExpandIcon={false}
                                listing={true}
                                headingComponent={
                                  <div className={styles.headingFlightComp}>
                                    <div>
                                      <p className={styles.checkInCheckout}>
                                        {FormatHotelListingDateFormat(
                                          cityData?.checkIn
                                        )}{" "}
                                        -{" "}
                                        {FormatHotelListingDateFormat(
                                          cityData?.checkOut
                                        )}
                                      </p>

                                      <p className={styles.city}>
                                        {cityData?.city}
                                      </p>
                                    </div>

                                    <FlightHotelSelect
                                      flight={false}
                                      selected={isThisHotelRoomSelected(
                                        selectedHotelRoomArr,
                                        cityData
                                      )}
                                    />
                                  </div>
                                }
                                bodyComponent={
                                  <div className={styles.hotelListing}>
                                    {cityData?.hotels
                                      ?.map((hotel: any) => ({
                                        ...hotel,
                                        checkIn: cityData?.checkIn,
                                        checkOut: cityData?.checkOut,
                                        city: cityData?.city,
                                      }))
                                      ?.filter(
                                        (hotel: any) =>
                                          hotel?.city === cityData?.city
                                      )
                                      ?.filter(
                                        (
                                          hotel: any,
                                          index: number,
                                          self: any[]
                                        ) =>
                                          index ===
                                          self?.findIndex(
                                            (h) => h?.hotelId === hotel?.hotelId
                                          )
                                      )
                                      ?.map(
                                        (hotel: any, hotelIndex: number) => {
                                          if (!hotel) return null;

                                          const {
                                            hotelMainImage,
                                            stars,
                                            name,
                                            address,
                                            rating,
                                            reviewCount,
                                            reviewHeading,
                                            amenities,
                                            totalPrice,
                                            cancellationPolicy,
                                            hotelId,
                                            checkIn = "",
                                            checkOut = "",
                                            city = "",
                                          } = hotel;

                                          return (
                                            <div key={hotelIndex}>
                                              <HotelListing
                                                id={hotelIndex}
                                                hotelMainImage={hotelMainImage}
                                                star={stars}
                                                hotelName={name}
                                                hotelLocation={address}
                                                hotelRating={rating}
                                                reviewCount={reviewCount}
                                                cost={totalPrice.price}
                                                cancellation={
                                                  cancellationPolicy
                                                }
                                                Amenity={amenities}
                                                hotelList={true}
                                                discount={""}
                                                discountCost={""}
                                                taxesIncluded={
                                                  totalPrice.taxesIncluded
                                                }
                                                reviewHeading={reviewHeading}
                                                hotelId={hotelId}
                                                hotelDetails={hotel}
                                                onSelect={() =>
                                                  handleHotelSelect(
                                                    hotelId,
                                                    checkIn,
                                                    checkOut,
                                                    city
                                                  )
                                                }
                                                city={city}
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    <div className={styles.showMoreBox}>
                                      {loadMore &&
                                      loadingCity === cityData?.city ? (
                                        <Loader />
                                      ) : (
                                        hotelPagination?.find(
                                          (hotel) =>
                                            hotel?.city === cityData?.city
                                        )?.hasMoreHotels && (
                                          <Button
                                            className={styles.showMoreBtn}
                                            endIcon={
                                              <img
                                                src={updatedSeeMoreIcon}
                                                alt="btn"
                                                className={styles.showMoreIcon}
                                              />
                                            }
                                            onClick={() =>
                                              handleShowMoreHotels(
                                                cityData?.city
                                              )
                                            }>
                                            Show More
                                          </Button>
                                        )
                                      )}
                                    </div>
                                  </div>
                                }
                                zeroMarginOnExpand
                                borderBottom
                              />
                            </div>
                          );
                        }
                      )}

                    {hotelData && hotelData?.length === 0 && (
                      <NoData
                        message={
                          TOAST_MESSAGES.BOOKING.Search
                            .NoHotelsFoundScreenMessage.message
                        }
                      />
                    )}
                  </section>
                ) : (
                  <section className={styles.listCard}>
                    {hotelData &&
                      hotelData?.data
                        ?.flatMap((cityData: any) =>
                          cityData?.hotels?.map((hotel: any) => ({
                            ...hotel,
                            checkIn: cityData?.checkIn,
                            checkOut: cityData?.checkOut,
                            city: cityData?.city,
                          }))
                        )
                        ?.filter(
                          (hotel: any, index: number, self: any[]) =>
                            index ===
                            self?.findIndex(
                              (h) => h?.hotelId === hotel?.hotelId
                            )
                        )
                        ?.map((hotel: any, index: number) => {
                          if (!hotel) return null;

                          const {
                            hotelMainImage,
                            stars,
                            name,
                            address,
                            rating,
                            reviewCount,
                            reviewHeading,
                            amenities,
                            totalPrice,
                            cancellationPolicy,
                            hotelId,
                            checkIn = "",
                            checkOut = "",
                            city = "",
                          } = hotel;

                          return (
                            <div key={index}>
                              <HotelListing
                                id={index}
                                hotelMainImage={hotelMainImage}
                                star={stars}
                                hotelName={name}
                                hotelLocation={address}
                                hotelRating={rating}
                                reviewCount={reviewCount}
                                cost={totalPrice.price}
                                cancellation={cancellationPolicy}
                                Amenity={amenities}
                                hotelList={true}
                                discount={""}
                                discountCost={""}
                                taxesIncluded={totalPrice.taxesIncluded}
                                reviewHeading={reviewHeading}
                                hotelId={hotelId}
                                hotelDetails={hotel}
                                onSelect={() =>
                                  handleHotelSelect(
                                    hotelId,
                                    checkIn,
                                    checkOut,
                                    city
                                  )
                                }
                                city={city}
                              />
                            </div>
                          );
                        })}
                    {hotelData?.data?.flatMap(
                      (cityData: any) => cityData?.hotels
                    )?.length >= showMore?.visibleList?.hotel && (
                      <div className={styles.packageShowMoreCard}>
                        {loadMore ? (
                          <Loader />
                        ) : (
                          <Button
                            className={styles.showMoreBtn}
                            endIcon={
                              <img
                                src={updatedSeeMoreIcon}
                                alt="btn"
                                className={styles.showMoreIcon}
                              />
                            }
                            onClick={handleSingleCityShowMoreHotels}>
                            Show More
                          </Button>
                        )}
                      </div>
                    )}
                    {hotelData && hotelData?.length === 0 && (
                      <NoData
                        message={
                          TOAST_MESSAGES.BOOKING.Search
                            .NoHotelsFoundScreenMessage.message
                        }
                      />
                    )}
                  </section>
                )}
              </>
            )}
            {isThisMultiCityTrip && hotelData?.data && (
              <section className={styles.buttonCard}>
                {!loading && (
                  <FlightSelectButtons
                    onContinue={selectFlightHotel}
                    // onContinue={() => setOpenBookedSuccessDialog(true)}
                    selectFlightHotel={selectFlightHotel}
                    selectedPackageId={selectedPackageId}
                    selectedFlightId={bookingDetails.selectedFlightId}
                    selectedHotelId={selectedHotelId}
                    flight={selectedFlightOptions.flights}
                    hotel={selectedFlightOptions.hotels}
                  />
                )}
              </section>
            )}
          </section>
        )}

        {/* Package listing */}
        {selectedFlightOptions?.hotels && selectedFlightOptions?.flights && (
          <>
            {(flightData?.length === 0 || hotelData?.data?.length === 0) &&
            !loading ? (
              <NoData message={"No flight and hotel data found"} />
            ) : (
              <>
                <section className={styles.list}>
                  {flightData && (
                    <section className={styles.selected}>
                      <SelectedOptions
                        selectedOptions={{ hotels: false, flights: true }}
                        selectTitle="Flight below"
                        flightData={flightData?.sectors || []}
                      />
                    </section>
                  )}
                  {!loading && (
                    <section className={styles.listCard}>
                      {flightData?.sectors
                        // ?.slice(0, bookingDetails.visibleList.flight)
                        ?.filter(
                          (flight: any, index: number, self: any[]) =>
                            index ===
                            self?.findIndex(
                              (f) => f?.uniqueId === flight?.uniqueId
                            )
                        )
                        ?.map((flight: any, index: number) => {
                          const outboundFlight = flight?.outbound?.[0];
                          if (!outboundFlight) return null;

                          const {
                            departureDate,
                            arrivalDate,
                            airlineName,
                            airlineImage,
                            stops,
                            stopsCount,
                            route,
                            transactionId,
                          } = outboundFlight;

                          return (
                            <div key={index}>
                              <FlightListing
                                selectedData={bookingDetails.selectedFlightId}
                                handleSelect={handleSelect}
                                key={index}
                                setFlightData={setFlightData}
                                flightData={flightData}
                                startTime={
                                  departureDate &&
                                  convertToCustomTime(departureDate)
                                }
                                endTime={
                                  arrivalDate &&
                                  convertToCustomTime(arrivalDate)
                                }
                                flightName={airlineName}
                                flightImage={airlineImage}
                                stopsInfo={
                                  stops
                                    ?.map((stop: any) => stop?.airport)
                                    .join(", ") || ""
                                }
                                stops={stopsCount}
                                stopTime={stops?.[0]?.layover || ""}
                                airports={route}
                                flightCost={flight?.price}
                                flightClass={bookingDetails.flightClass}
                                tourPackage={true}
                                buttonName="Book"
                                sup="1"
                                detailsButton={"FLight Details"}
                                handleBook={() => {
                                  // navigate(ROUTES.TravelerInformation)
                                }}
                                selected={
                                  bookingDetails.selectedFlightId ===
                                  transactionId
                                }
                                encryptedKey={flightData?.encryptedKey}
                                uniqueId={flight?.uniqueId}
                                inOut="outbound"
                              />
                            </div>
                          );
                        })}
                      {/* {flightData?.sectors
                    ?.slice(0, bookingDetails.visibleList.flight)
                    ?.filter((flight: any, index: number, self: any[]) => {
                      return (
                        self.findIndex(
                          (f: any) => f.uniqueId === flight.uniqueId
                        ) === index
                      );
                    })
                    ?.map((flight: any, index: number) => {
                      const inboundFlight = flight?.inbound?.[0];

                      if (!inboundFlight) return null;

                      const {
                        departureDate,
                        arrivalDate,
                        airlineName,
                        airlineImage,
                        stops,
                        stopsCount,
                        route,
                        transactionId,
                      } = inboundFlight;

                      return (
                        <div key={index}>
                          <FlightListing
                            setFlightData={setFlightData}
                            flightData={flightData}
                            key={index}
                            startTime={
                              departureDate &&
                              convertToCustomTime(departureDate)
                            }
                            endTime={
                              arrivalDate && convertToCustomTime(arrivalDate)
                            }
                            flightName={airlineName}
                            flightImage={airlineImage}
                            stopsInfo={
                              stops
                                ?.map((stop: any) => stop?.airport)
                                .join(", ") || ""
                            }
                            stops={stopsCount}
                            stopTime={stops?.[0]?.layover || ""}
                            airports={route}
                            flightCost={flight?.price}
                            flightClass={bookingDetails.flightClass}
                            tourPackage={true}
                            buttonName="Book"
                            sup="1"
                            detailsButton={"FLight Details"}
                            handleBook={() => {
                              // navigate(ROUTES.TravelerInformation)
                            }}
                            selected={selectedFlightId === transactionId}
                            onSelect={() =>
                              handleFlightCostChange(transactionId)
                            }
                            encryptedKey={flightData?.encryptedKey}
                            uniqueId={flight?.uniqueId}
                            inOut="inbound"
                          />
                        </div>
                      );
                    })} */}
                      {flightData?.sectors && (
                        <>
                          {bookingDetails?.noMoreFlights ? null : (
                            <div className={styles.packageShowMoreCard}>
                              {loadMoreFlights ? (
                                <Loader />
                              ) : (
                                <Button
                                  className={styles.showMoreBtn}
                                  endIcon={
                                    <img
                                      src={updatedSeeMoreIcon}
                                      alt="btn"
                                      className={styles.showMoreIcon}
                                    />
                                  }
                                  onClick={handleShowMoreFlights}>
                                  Show More
                                </Button>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </section>
                  )}
                  {flightData?.sectors && flightData?.sectors?.length === 0 && (
                    <NoData
                      message={
                        TOAST_MESSAGES.BOOKING.Search
                          .NoFlightsFoundScreenMessage.message
                      }
                    />
                  )}
                  {flightData && flightData.length === 0 && (
                    <>
                      {loading ? null : (
                        <NoData
                          message={
                            TOAST_MESSAGES.BOOKING.Search
                              .NoFlightsFoundScreenMessage.message
                          }
                        />
                      )}
                    </>
                  )}
                </section>
                <section className={styles.list}>
                  {hotelData?.data && (
                    <section className={styles.selected}>
                      <SelectedOptions
                        selectedOptions={{ hotels: true, flights: false }}
                      />
                    </section>
                  )}
                  {/* )} */}

                  {!loading && (
                    <>
                      {isThisMultiCityTrip ? (
                        <section className={styles.listCard}>
                          {hotelData &&
                            hotelData?.data?.map(
                              (cityData: any, cityIndex: number) => {
                                if (!cityData?.hotels?.length) {
                                  return (
                                    <div
                                      key={cityIndex}
                                      className={styles.listCardContainer}>
                                      <p className={styles.noHotelsMessage}>
                                        No hotels found in {cityData?.city}
                                      </p>
                                    </div>
                                  );
                                }

                                return (
                                  <div
                                    key={cityIndex}
                                    className={styles.listCardContainer}>
                                    <CustomAccordion
                                      className={styles.customFlightStyles}
                                      summaryClass={styles.summaryClass}
                                      accordianDetailsClass={
                                        styles.accordFlightDetailsClass
                                      }
                                      // defaultExpanded={cityIndex === 0}
                                      defaultExpanded={
                                        bookingDetails?.expandedAccordions?.includes(
                                          cityIndex
                                        ) || false
                                      }
                                      onChange={() =>
                                        handleToggleAccordion(cityIndex)
                                      }
                                      hideExpandIcon={false}
                                      listing={true}
                                      headingComponent={
                                        <div
                                          className={styles.headingFlightComp}>
                                          <div>
                                            <p
                                              className={
                                                styles.checkInCheckout
                                              }>
                                              {FormatHotelListingDateFormat(
                                                cityData?.checkIn
                                              )}{" "}
                                              -{" "}
                                              {FormatHotelListingDateFormat(
                                                cityData?.checkOut
                                              )}
                                            </p>

                                            <p className={styles.city}>
                                              {cityData?.city}
                                            </p>
                                          </div>

                                          <FlightHotelSelect
                                            flight={false}
                                            selected={isThisHotelRoomSelected(
                                              selectedHotelRoomArr,
                                              cityData
                                            )}
                                          />
                                        </div>
                                      }
                                      bodyComponent={
                                        <div className={styles.hotelListing}>
                                          {cityData.hotels
                                            ?.map((hotel: any) => ({
                                              ...hotel,
                                              checkIn: cityData?.checkIn,
                                              checkOut: cityData?.checkOut,
                                              city: cityData?.city,
                                            }))
                                            ?.filter(
                                              (hotel: any) =>
                                                hotel?.city === cityData?.city
                                            )
                                            ?.filter(
                                              (
                                                hotel: any,
                                                index: number,
                                                self: any[]
                                              ) =>
                                                index ===
                                                self?.findIndex(
                                                  (h) =>
                                                    h?.hotelId ===
                                                    hotel?.hotelId
                                                )
                                            )
                                            ?.map(
                                              (
                                                hotel: any,
                                                hotelIndex: number
                                              ) => {
                                                if (!hotel) return null;

                                                const {
                                                  hotelMainImage,
                                                  stars,
                                                  name,
                                                  address,
                                                  rating,
                                                  reviewCount,
                                                  reviewHeading,
                                                  amenities,
                                                  totalPrice,
                                                  cancellationPolicy,
                                                  hotelId,
                                                  checkIn = "",
                                                  checkOut = "",
                                                  city = "",
                                                } = hotel;

                                                return (
                                                  <div key={hotelIndex}>
                                                    <HotelListing
                                                      id={hotelIndex}
                                                      hotelMainImage={
                                                        hotelMainImage
                                                      }
                                                      star={stars}
                                                      hotelName={name}
                                                      hotelLocation={address}
                                                      hotelRating={rating}
                                                      reviewCount={reviewCount}
                                                      cost={totalPrice.price}
                                                      cancellation={
                                                        cancellationPolicy
                                                      }
                                                      Amenity={amenities}
                                                      hotelList={true}
                                                      discount={""}
                                                      discountCost={""}
                                                      taxesIncluded={
                                                        totalPrice.taxesIncluded
                                                      }
                                                      reviewHeading={
                                                        reviewHeading
                                                      }
                                                      hotelId={hotelId}
                                                      hotelDetails={hotel}
                                                      onSelect={() =>
                                                        handleHotelSelect(
                                                          hotelId,
                                                          checkIn,
                                                          checkOut,
                                                          city
                                                        )
                                                      }
                                                      city={city}
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                          <div className={styles.showMoreBox}>
                                            {loadMore &&
                                            loadingCity === cityData?.city ? (
                                              <Loader />
                                            ) : (
                                              hotelPagination?.find(
                                                (hotel) =>
                                                  hotel?.city === cityData?.city
                                              )?.hasMoreHotels && (
                                                <Button
                                                  className={styles.showMoreBtn}
                                                  endIcon={
                                                    <img
                                                      src={updatedSeeMoreIcon}
                                                      alt="btn"
                                                      className={
                                                        styles.showMoreIcon
                                                      }
                                                    />
                                                  }
                                                  onClick={() =>
                                                    handleShowMoreHotels(
                                                      cityData?.city
                                                    )
                                                  }>
                                                  Show More
                                                </Button>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      }
                                      zeroMarginOnExpand
                                      borderBottom
                                    />
                                  </div>
                                );
                              }
                            )}

                          {hotelData && hotelData?.length === 0 && (
                            <NoData
                              message={
                                TOAST_MESSAGES.BOOKING.Search
                                  .NoHotelsFoundScreenMessage.message
                              }
                            />
                          )}
                        </section>
                      ) : (
                        <section className={styles.listCard}>
                          {hotelData &&
                            hotelData?.data
                              ?.flatMap((cityData: any) =>
                                cityData?.hotels?.map((hotel: any) => ({
                                  ...hotel,
                                  checkIn: cityData?.checkIn,
                                  checkOut: cityData?.checkOut,
                                  city: cityData?.city,
                                }))
                              )
                              ?.filter(
                                (hotel: any, index: number, self: any[]) =>
                                  index ===
                                  self?.findIndex(
                                    (h) => h?.hotelId === hotel?.hotelId
                                  )
                              )
                              ?.map((hotel: any, index: number) => {
                                if (!hotel) return null;

                                const {
                                  hotelMainImage,
                                  stars,
                                  name,
                                  address,
                                  rating,
                                  reviewCount,
                                  reviewHeading,
                                  amenities,
                                  totalPrice,
                                  cancellationPolicy,
                                  hotelId,
                                  checkIn = "",
                                  checkOut = "",
                                  city = "",
                                } = hotel;

                                return (
                                  <div key={index}>
                                    <HotelListing
                                      id={index}
                                      hotelMainImage={hotelMainImage}
                                      star={stars}
                                      hotelName={name}
                                      hotelLocation={address}
                                      hotelRating={rating}
                                      reviewCount={reviewCount}
                                      cost={totalPrice.price}
                                      cancellation={cancellationPolicy}
                                      Amenity={amenities}
                                      hotelList={true}
                                      discount={""}
                                      discountCost={""}
                                      taxesIncluded={totalPrice.taxesIncluded}
                                      reviewHeading={reviewHeading}
                                      hotelId={hotelId}
                                      hotelDetails={hotel}
                                      onSelect={() =>
                                        handleHotelSelect(
                                          hotelId,
                                          checkIn,
                                          checkOut,
                                          city
                                        )
                                      }
                                      city={city}
                                    />
                                  </div>
                                );
                              })}
                          {hotelData?.data?.flatMap(
                            (cityData: any) => cityData?.hotels
                          )?.length >= showMore?.visibleList?.hotel && (
                            <div className={styles.packageShowMoreCard}>
                              {loadMore ? (
                                <Loader />
                              ) : (
                                <Button
                                  className={styles.showMoreBtn}
                                  endIcon={
                                    <img
                                      src={updatedSeeMoreIcon}
                                      alt="btn"
                                      className={styles.showMoreIcon}
                                    />
                                  }
                                  onClick={handleSingleCityShowMoreHotels}>
                                  Show More
                                </Button>
                              )}
                            </div>
                          )}
                          {hotelData && hotelData?.length === 0 && (
                            <NoData
                              message={
                                TOAST_MESSAGES.BOOKING.Search
                                  .NoHotelsFoundScreenMessage.message
                              }
                            />
                          )}
                        </section>
                      )}
                    </>
                  )}
                  {((flightData && flightData.length > 0) ||
                    hotelData?.data) && (
                    <section className={styles.buttonCard}>
                      {!loading && (
                        <FlightSelectButtons
                          onContinue={selectFlightHotel}
                          // onContinue={() => setOpenBookedSuccessDialog(true)}
                          selectFlightHotel={selectFlightHotel}
                          selectedPackageId={selectedPackageId}
                          selectedFlightId={bookingDetails.selectedFlightId}
                          selectedHotelId={selectedHotelId}
                          flight={selectedFlightOptions.flights}
                          hotel={selectedFlightOptions.hotels}
                        />
                      )}
                    </section>
                  )}
                </section>
              </>
            )}
          </>
        )}
        {!loading && (
          <div className={styles.landingCard}>
            {!flightData &&
              selectedFlightOptions.flights &&
              !selectedFlightOptions.hotels && <BookingLandingPage />}
            {!hotelData &&
              selectedFlightOptions.hotels &&
              !selectedFlightOptions.flights && <BookingLandingPage />}
            {!flightData &&
              !hotelData &&
              selectedFlightOptions.hotels &&
              selectedFlightOptions.flights && <BookingLandingPage />}
          </div>
        )}
      </div>
    </div>
  );
}

export default Booking;
