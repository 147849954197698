import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import styles from "./airportSelectionPopover.module.scss";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Airport, BookingDetails } from "../../../../types";
import { getAirports } from "../../../../api/booking";
import Loader from "../../../reusableComponent/loader/Loader";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { StateContext } from "../../../../context/globalContext/context";
import {
  CityAirports,
  iBookingAirportLoaderTypes,
} from "../../../../interface";
import AutocompleteAirportAddress from "../../../airportAutoCompleteAddress/airportAutoCompleteAddress";
import { toastMessage } from "../../../../utils/ToastProvider";
import AutoCompleteArrivalAirportAddress from "../../../airportArrivalAutoCompleteAddress/airportArrivalAutoCompleteAddress";

interface iProps {
  popoverTrigger: ReactNode;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleApplyButton: () => void;
  departureCity?: string;
  arrivalCity?: string;
  indexNumber: number;
}

export default function AirportSelectionPopover({
  popoverTrigger,
  bookingDetails,
  setBookingDetails,
  isPopoverOpen,
  setIsPopoverOpen,
  handleApplyButton,
  departureCity,
  arrivalCity,
  indexNumber,
}: iProps) {
  const state = useContext(StateContext);

  const { selectedChatData, airPorts, setAirPorts }: iGlobalContext = state;
  const handleArrivalPlaceSelected = () => {
    setBookingDetails((prevData) => ({
      ...prevData,
      isFormUpdated: {
        ...prevData.isFormUpdated,
        arrivalForm: true,
      },
    }));
  };
  const handleDeparturePlaceSelected = () => {
    setBookingDetails((prevData) => ({
      ...prevData,
      isFormUpdated: {
        ...prevData.isFormUpdated,
        departureForm: true,
      },
    }));
  };
  const [loader, setLoader] = useState<iBookingAirportLoaderTypes>({
    arrivalLoader: false,
    departureLoader: false,
  });

  let fetchAirportController: any = useRef(null);

  const handleArrival = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAirport = e.target.value;
    const selectedAirportDetails = airPorts
      .flatMap((city: { airports: any }) => city.airports)
      .find((airport: Airport) => airport.code === selectedAirport);
    const selectedCity = airPorts.find((city: { airports: any[] }) =>
      city.airports.some((airport) => airport.code === selectedAirport)
    )?.city;
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      airportTo: selectedAirport,
      airPort: prevDetails.airPort.map((flight, index) =>
        index === indexNumber
          ? {
              ...flight,
              arrival: selectedAirport,
              destinationCity: selectedCity || "",
              arrivalAirportFullName: selectedAirportDetails?.fullName || "",
            }
          : flight
      ),
      update: true,
    }));
  };

  const handleDeparture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAirport = e.target.value;

    // Find the selected airport and get its details
    const selectedAirportDetails = airPorts
      .flatMap((city: { airports: any }) => city.airports)
      .find((airport: Airport) => airport.code === selectedAirport);

    const selectedCity = airPorts.find((city: { airports: Airport[] }) =>
      city.airports.some((airport: Airport) => airport.code === selectedAirport)
    )?.city;

    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      airportFrom: selectedAirport,
      airPort: prevDetails.airPort.map((flight, index) =>
        index === indexNumber
          ? {
              ...flight,
              departure: selectedAirport,
              fromCity: selectedCity || "",
              departureAirportFullName: selectedAirportDetails?.fullName || "",
            }
          : flight
      ),
      update: true,
    }));
  };

  useEffect(() => {
    if (airPorts && selectedChatData) {
      const departureAirportName = selectedChatData.departure_airport;
      const arrivalAirportName = selectedChatData.arrival_airport;
      // Filter airports for departure based on the selected city
      const filteredDepartureAirports = airPorts
        ?.filter((city: CityAirports) => {
          const fromCity =
            bookingDetails.flightHistory[0]?.flights[0]?.departure_city_name;
          return city.city === fromCity;
        })
        .flatMap((city: CityAirports) => city.airports.slice(0, 5));

      // Filter airports for arrival based on cities not equal to fromCity
      const filteredArrivalAirports = airPorts
        ?.filter((city: CityAirports) => {
          const arrivalCity =
            bookingDetails.flightHistory[0]?.flights[0]?.arrival_city_name;
          return city.city === arrivalCity;
        })
        .flatMap((city: CityAirports) => city?.airports?.slice(0, 5));

      // Find matching departure airport by name or default to the first option
      const matchingDepartureAirport =
        filteredDepartureAirports.find(
          (airport) => airport.fullName === departureAirportName
        ) || filteredDepartureAirports[0];

      // Set airportFrom to matching or default departure airport's code if found
      if (matchingDepartureAirport && !bookingDetails?.airPort[0]?.departure) {
        const departureCity = airPorts.find((city: CityAirports) =>
          city.airports.some(
            (airport) => airport.code === matchingDepartureAirport.code
          )
        )?.city;

        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airportFrom: matchingDepartureAirport.code,
          airPort: prevDetails.airPort.map((flight, index) =>
            index === 0
              ? {
                  ...flight,
                  departure: matchingDepartureAirport.code,
                  fromCity: departureCity || "",
                  departureAirportFullName:
                    matchingDepartureAirport.fullName || "",
                }
              : flight
          ),
          update: false,
        }));
      }

      // Find matching arrival airport by name or default to the first option
      const matchingArrivalAirport =
        filteredArrivalAirports.find(
          (airport) => airport.fullName === arrivalAirportName
        ) || filteredArrivalAirports[0];

      // Set airportTo to matching or default arrival airport's code if found
      if (matchingArrivalAirport && !bookingDetails?.airPort[0]?.arrival) {
        const arrivalCity = airPorts.find((city: CityAirports) =>
          city.airports.some(
            (airport) => airport.code === matchingArrivalAirport.code
          )
        )?.city;

        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          airportTo: matchingArrivalAirport.code,
          airPort: prevDetails.airPort.map((flight, index) =>
            index === 0
              ? {
                  ...flight,
                  arrival: matchingArrivalAirport.code,
                  destinationCity: arrivalCity || "",
                  arrivalAirportFullName: matchingArrivalAirport.fullName || "",
                }
              : flight
          ),
          update: false,
        }));
      }
    }
  }, [
    airPorts,
    selectedChatData,
    bookingDetails.airportFrom,
    bookingDetails.airportTo,
    setBookingDetails,
    bookingDetails?.airPort,
    bookingDetails.flightHistory,
  ]);

  const handleSearchAirport = async () => {
    if (bookingDetails.travelFromDate === "") {
      toastMessage.error("Please select travel date");
      return;
    }
    const cities = [
      {
        name: `${bookingDetails.selectedLocation.city}, ${bookingDetails.selectedLocation.state}, ${bookingDetails.selectedLocation.country}`,
        latitude: bookingDetails.selectedLocation?.latitude?.toString() || "",
        longitude: bookingDetails.selectedLocation?.longitude?.toString() || "",
        countryCode: bookingDetails.selectedLocation.countryCode,
      },
    ];

    if (bookingDetails.selectedLocation.city === "") {
      toastMessage.error("Please enter city details");
      return;
    }
    setLoader((prevData) => ({
      ...prevData,
      departureLoader: true,
      arrivalLoader: false,
    }));
    const requestBody = {
      cities: cities,
      radius: "100 miles",
    };

    fetchAirportController.current = new AbortController();

    try {
      const data = await getAirports(
        requestBody,
        fetchAirportController.current.signal
      );

      if (fetchAirportController.current.signal.aborted) {
        return;
      }
      if (data) {
        if (Array.isArray(data)) {
          setAirPorts((prevData) => [...prevData, ...data]);
        }
        setBookingDetails((prevData) => ({
          ...prevData,
          isFormUpdated: {
            ...prevData.isFormUpdated,
            departureForm: false,
          },
        }));
      }
    } catch (err) {
      setAirPorts([]);
      console.error("Failed to fetch flight data", err);
      setBookingDetails((prevData) => ({
        ...prevData,
        isFormUpdated: {
          ...prevData.isFormUpdated,
          departureForm: false,
        },
      }));
    } finally {
      setLoader((prevData) => ({
        ...prevData,
        departureLoader: false,
        arrivalLoader: false,
      }));
      setBookingDetails((prevData) => ({
        ...prevData,
        isFormUpdated: {
          ...prevData.isFormUpdated,
          departureForm: false,
        },
      }));
    }
  };

  const handleSearchArrivalAirport = async () => {
    if (bookingDetails.travelFromDate === "") {
      toastMessage.error("Please select travel date");
      return;
    }
    const cities = [
      {
        name: `${bookingDetails.selectedArrivalLocation.city}, ${bookingDetails.selectedArrivalLocation.state}, ${bookingDetails.selectedArrivalLocation.country}`,
        latitude:
          bookingDetails.selectedArrivalLocation?.latitude?.toString() || "",
        longitude:
          bookingDetails.selectedArrivalLocation?.longitude?.toString() || "",
        countryCode: bookingDetails.selectedArrivalLocation.countryCode,
      },
    ];

    if (bookingDetails.selectedArrivalLocation.city === "") {
      toastMessage.error("Please enter city details");
      return;
    }
    setLoader((prevData) => ({
      ...prevData,
      departureLoader: false,
      arrivalLoader: true,
    }));
    const requestBody = {
      cities: cities,
      radius: "100 miles",
    };

    fetchAirportController.current = new AbortController();

    try {
      const data = await getAirports(
        requestBody,
        fetchAirportController.current.signal
      );

      if (fetchAirportController.current.signal.aborted) {
        return;
      }
      if (data) {
        if (Array.isArray(data)) {
          setAirPorts((prevData) => [...prevData, ...data]);
        }
        setBookingDetails((prevData) => ({
          ...prevData,
          isFormUpdated: {
            ...prevData.isFormUpdated,
            arrivalForm: false,
          },
        }));
      }
    } catch (err) {
      setAirPorts([]);
      console.error("Failed to fetch flight data", err);
      setBookingDetails((prevData) => ({
        ...prevData,
        isFormUpdated: {
          ...prevData.isFormUpdated,
          arrivalForm: false,
        },
      }));
    } finally {
      setLoader((prevData) => ({
        ...prevData,
        departureLoader: false,
        arrivalLoader: false,
      }));
      setBookingDetails((prevData) => ({
        ...prevData,
        isFormUpdated: {
          ...prevData.isFormUpdated,
          arrivalForm: false,
        },
      }));
    }
  };
  const handleDepart = async () => {
    setBookingDetails((prevData) => ({ ...prevData, searchType: "departure" }));
    setBookingDetails((prevData) => ({
      ...prevData,
      cityInfo: {
        ...prevData.cityInfo,
        departure: `${bookingDetails.selectedLocation.city}, ${bookingDetails.selectedLocation.state}, ${bookingDetails.selectedLocation.country}`,
      },
      airPort: prevData.airPort.map((airport, index) =>
        index === 0
          ? {
              ...airport,
              newDepartureCitySearch: true,
              departureSelectedLocation: {
                city: bookingDetails.selectedLocation?.city || "",
                state: bookingDetails.selectedLocation?.state || "",
                country: bookingDetails.selectedLocation?.country || "",
                countryCode: bookingDetails.selectedLocation.countryCode || "",
                latitude: bookingDetails.selectedLocation?.latitude || 0,
                longitude: bookingDetails.selectedLocation?.longitude || 0,
              },
            }
          : airport
      ),
    }));

    await handleSearchAirport();
  };
  const previousLocation = useRef<BookingDetails["selectedLocation"] | null>(
    null
  );
  const previousArrivalLocation = useRef<
    BookingDetails["selectedArrivalLocation"] | null
  >(null);
  useEffect(() => {
    const currentLocation = bookingDetails.selectedLocation;

    if (
      currentLocation.city &&
      currentLocation.state &&
      currentLocation.country &&
      JSON.stringify(currentLocation) !==
        JSON.stringify(previousLocation.current)
    ) {
      handleDepart();
      previousLocation.current = currentLocation;
    }
  }, [bookingDetails.airPort]);

  const handleArriv = async () => {
    setBookingDetails((prevData) => ({ ...prevData, searchType: "arrival" }));
    setBookingDetails((prevData) => ({
      ...prevData,
      cityInfo: {
        ...prevData.cityInfo,
        arrival: `${prevData.selectedArrivalLocation?.city || ""}, ${prevData.selectedArrivalLocation?.state || ""}, ${prevData.selectedArrivalLocation?.country || ""}`,
      },
      airPort: prevData.airPort.map((airport, index) =>
        index === 0
          ? {
              ...airport,
              newArrivalCitySearch: true,
              arrivalSelectedLocation: {
                city: bookingDetails.selectedArrivalLocation?.city || "",
                state: bookingDetails.selectedArrivalLocation?.state || "",
                country: bookingDetails.selectedArrivalLocation?.country || "",
                countryCode:
                  bookingDetails.selectedArrivalLocation.countryCode || "",
                latitude: bookingDetails.selectedArrivalLocation?.latitude || 0,
                longitude:
                  bookingDetails.selectedArrivalLocation?.longitude || 0,
              },
            }
          : airport
      ),
    }));
    await handleSearchArrivalAirport();
  };
  useEffect(() => {
    const currentLocation = bookingDetails.selectedArrivalLocation;

    if (
      currentLocation.city &&
      currentLocation.state &&
      currentLocation.country &&
      JSON.stringify(currentLocation) !==
        JSON.stringify(previousArrivalLocation.current)
    ) {
      handleArriv();
      previousArrivalLocation.current = currentLocation;
    }
  }, [bookingDetails.airPort]);

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <>
            <h1 className={styles.heading}>Departure Airport</h1>
            <div className={styles.autoCompleteField}>
              <div className={styles.input}>
                <AutocompleteAirportAddress
                  onPlaceSelected={handleDeparturePlaceSelected}
                  setSelectedLocation={setBookingDetails}
                  setAddress={setBookingDetails}
                  address={`${bookingDetails?.airPort[0]?.newDepartureCitySearch ? bookingDetails?.airPort[0]?.departureCityDetails : bookingDetails?.airPort[0]?.fromCity}`}
                />
              </div>
            </div>
            {loader.departureLoader ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : (
              <>
                {Array.isArray(airPorts) &&
                airPorts
                  .reduce(
                    (uniqueCities: CityAirports[], city: CityAirports) => {
                      // Check if the city is already added
                      if (!uniqueCities.some((c) => c.city === city.city)) {
                        uniqueCities.push(city);
                      }
                      return uniqueCities;
                    },
                    []
                  )
                  ?.filter((city: CityAirports) => {
                    // const fromCity =
                    //   selectedChatData?.fromCity ??
                    //   selectedChatData?.from;
                    const fromCity =
                      bookingDetails?.flightHistory[0]?.flights[0]
                        ?.departure_city_name;

                    return (
                      city.city ===
                      `${bookingDetails?.airPort[0]?.newDepartureCitySearch ? bookingDetails?.airPort[0]?.departureCityDetails : bookingDetails?.airPort[0]?.fromCity}`
                    );
                  }).length === 0 ? (
                  <p className={styles.errorMessage}>
                    {" "}
                    Unable to find an airport.
                    <br /> Try a different location.
                  </p>
                ) : (
                  <FormControl className={styles.formControlContainer}>
                    {bookingDetails?.airPort[0]?.newDepartureCitySearch &&
                    bookingDetails.isFormUpdated.departureForm ===
                      true ? null : (
                      <RadioGroup
                        name="radio"
                        onChange={handleDeparture}
                        value={bookingDetails.airportFrom}>
                        {Array.isArray(airPorts) &&
                          airPorts
                            .reduce(
                              (
                                uniqueCities: CityAirports[],
                                city: CityAirports
                              ) => {
                                if (
                                  !uniqueCities.some(
                                    (c) => c.city === city.city
                                  )
                                ) {
                                  uniqueCities.push(city);
                                }
                                return uniqueCities;
                              },
                              []
                            )

                            ?.filter((city: CityAirports) => {
                              // const fromCity =
                              //   selectedChatData?.fromCity ??
                              //   selectedChatData?.from;
                              const fromCity =
                                bookingDetails?.flightHistory[0]?.flights[0]
                                  ?.departure_city_name;

                              return (
                                city.city ===
                                `${bookingDetails?.airPort[0]?.newDepartureCitySearch ? bookingDetails?.airPort[0]?.departureCityDetails : bookingDetails?.airPort[0]?.fromCity}`
                              );
                            })

                            .map((city: CityAirports) => (
                              <div key={city.city}>
                                {city?.airports
                                  ?.slice(0, 5)
                                  .map((airport: Airport) => (
                                    <FormControlLabel
                                      key={airport.code}
                                      value={airport.code}
                                      control={<CustomRadio />}
                                      label={
                                        <p
                                          className={styles.airportLabel}
                                          style={{
                                            color:
                                              airport.code ===
                                              bookingDetails.airportFrom
                                                ? "#222222"
                                                : "#696969",
                                          }}>
                                          {airport?.code} - {airport?.fullName}
                                        </p>
                                      }
                                    />
                                  ))}
                              </div>
                            ))}
                      </RadioGroup>
                    )}
                  </FormControl>
                )}
              </>
            )}

            <h1 className={styles.heading}>Arrival Airport</h1>
            <div className={styles.autoCompleteField}>
              <AutoCompleteArrivalAirportAddress
                onPlaceSelected={handleArrivalPlaceSelected}
                setSelectedLocation={setBookingDetails}
                setAddress={setBookingDetails}
                // address={bookingDetails.arrCity}
                address={`${
                  bookingDetails.airPort[0].newArrivalCitySearch
                    ? bookingDetails.airPort[0].arrivalCityDetails
                    : bookingDetails.airPort[0].destinationCity
                }`}
              />
            </div>
            {loader.arrivalLoader ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : (
              <>
                {Array.isArray(airPorts) &&
                airPorts
                  .reduce(
                    (uniqueCities: CityAirports[], city: CityAirports) => {
                      // Check if the city is already added
                      if (!uniqueCities.some((c) => c.city === city.city)) {
                        uniqueCities.push(city);
                      }
                      return uniqueCities;
                    },
                    []
                  )
                  ?.filter((city: CityAirports) => {
                    // const fromCity =
                    //   selectedChatData?.fromCity ??
                    //   selectedChatData?.from;
                    const arrivalCity =
                      bookingDetails?.flightHistory[0]?.flights[0]
                        ?.arrival_city_name;

                    return (
                      city.city ===
                      `${bookingDetails?.airPort[0]?.newArrivalCitySearch ? bookingDetails.airPort[0].arrivalCityDetails : bookingDetails.airPort[0].destinationCity}`
                    );
                  }).length === 0 ? (
                  <p className={styles.errorMessage}>
                    Unable to find an airport.
                    <br /> Try a different location.
                  </p>
                ) : (
                  <FormControl className={styles.formControlContainer}>
                    {bookingDetails?.airPort[0]?.newArrivalCitySearch &&
                    bookingDetails.isFormUpdated.arrivalForm === true ? null : (
                      <RadioGroup
                        name="radio"
                        onChange={handleArrival}
                        value={bookingDetails.airportTo}>
                        {Array.isArray(airPorts) &&
                          airPorts
                            .reduce(
                              (
                                uniqueCities: CityAirports[],
                                city: CityAirports
                              ) => {
                                if (
                                  !uniqueCities.some(
                                    (c) => c.city === city.city
                                  )
                                ) {
                                  uniqueCities.push(city);
                                }
                                return uniqueCities;
                              },
                              []
                            )

                            ?.filter((city: CityAirports) => {
                              // const fromCity =
                              //   selectedChatData?.fromCity ??
                              //   selectedChatData?.from;
                              const arrivalCity =
                                bookingDetails?.flightHistory[0]?.flights[0]
                                  ?.arrival_city_name;

                              return (
                                city.city ===
                                `${bookingDetails?.airPort[0]?.newArrivalCitySearch ? bookingDetails.airPort[0].arrivalCityDetails : bookingDetails.airPort[0].destinationCity}`
                              );
                            })
                            .map((city: CityAirports) => (
                              <div key={city.city}>
                                {city?.airports
                                  ?.slice(0, 5)
                                  .map((airport: Airport) => (
                                    <FormControlLabel
                                      key={airport.code}
                                      value={airport.code}
                                      control={<CustomRadio />}
                                      label={
                                        <p
                                          className={styles.airportLabel}
                                          style={{
                                            color:
                                              airport.code ===
                                              bookingDetails.airportTo
                                                ? "#222222"
                                                : "#696969",
                                          }}>
                                          {airport.code} - {airport.fullName}
                                          <br />
                                          <p className={styles.distance}>
                                            {" "}
                                            {airport.distance} miles from{" "}
                                            {city.city}
                                          </p>
                                        </p>
                                      }
                                    />
                                  ))}
                              </div>
                            ))}
                      </RadioGroup>
                    )}
                  </FormControl>
                )}
              </>
            )}
          </>
        </div>
      }
      handleApplyButton={handleApplyButton}
    />
  );
}

export function CustomRadio(props: RadioProps) {
  return (
    <Radio
      // disableRipple
      color="default"
      {...props}
      icon={<span className={styles.icon} />}
      checkedIcon={<span className={styles.checkedIcon} />}
    />
  );
}
