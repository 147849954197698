import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { iGlobalContext } from "../globalContext/interface";
import { StateContext } from "../globalContext/context";
import { getHistoryAPICall } from "../../api/getHistory";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { apiFailedTryAgain, getConfigMessage } from "../../utils";
import { APP_CONFIG } from "../../constant/common";
import { sortDatesInAscendingOrder } from "../../utils/dateUtils";
import { iHistoryContext } from "./interface";
import { addHistoryAPI } from "../../api/saveHistory";
import { useInView } from "react-intersection-observer";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { useLocalStorage } from "../../hooks/useLocalStorage";
const HistoryContext = createContext({} as iHistoryContext);
export const HistoryContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [todayHistory, setTodayHistory] = useState<any>([]);
  const [yesterdayHistory, setYesterdayHistory] = useState<any>([]);
  const [oneWeekAgoHistory, setOneWeekAgoHistory] = useState<any>([]);
  const [lastOneMonth, setLastOneMonth] = useState<any>([]);
  const globalContext = useContext(StateContext);
  const { userDetails, appConfig }: iGlobalContext = globalContext;
  const { handleCustomError } = useCustomErrorHandler();
  const [hasMoreHistory, setHasMoreHistory] = useState(true);
  const [skipLimitHistory, setSkipLimitHistory] = useState({
    skip: 0,
    limit: 20,
  });
  const { ref: historyPaginationLoaderRef, inView } = useInView({});

  function resetState() {
    setTodayHistory((prev: any) => []);
    setYesterdayHistory((prev: any) => []);
    setOneWeekAgoHistory((prev: any) => []);
    setLastOneMonth((prev: any) => []);
    setSkipLimitHistory({
      skip: 0,
      limit: 20,
    });
    setHasMoreHistory(true);
  }

  useEffect(() => {
    if (!userDetails?.id) {
      resetState();
    }
  }, [userDetails]);

  useEffect(() => {
    console.log("VIEW", inView, skipLimitHistory);
    if (inView) getHistory();
  }, [inView]);

  async function getHistory(reload?: boolean, openWithSideBar?: boolean) {
    if ((!userDetails?.id || !hasMoreHistory) && !openWithSideBar) return;

    setIsLoading(true);
    if (reload) resetState();
    const response = await getHistoryAPICall(
      userDetails.id,
      reload ? 0 : skipLimitHistory?.skip,
      reload && skipLimitHistory?.skip > 0
        ? skipLimitHistory?.skip
        : skipLimitHistory?.limit
    );
    // if (response?.statusCode !== 200) {
    //   handleCustomError(response?.statusCode, "view.history");
    //   return null;
    // }
    if (response) {
      setIsLoading(false);
      // // console.log(response);
      console.log({ response });
      if (response?.length === 0) {
        setHasMoreHistory(false);
      }
    }
    if (!response) {
      // toastMessage.error(
      //   "OOPS!",
      //   getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      // );
      return;
    }
    // // console.log(response);
    const yesterday = new Date(
      new Date().setDate(new Date().getUTCDate() - 2)
    ).getTime();
    const now = new Date();
    const startOfToday = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate()
    ).getTime();
    const endOfToday = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      23,
      59,
      59,
      999
    ).getTime();

    const startOfYesterday = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() - 1
    ).getTime();
    const endOfYesterday = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() - 1,
      23,
      59,
      59,
      999
    ).getTime();

    // Calculate the start of the day one week ago
    const startOfWeekAgo = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() - 7,
      0,
      0,
      0,
      0
    ).getTime();

    setSkipLimitHistory({
      skip: skipLimitHistory.skip + skipLimitHistory.limit,
      limit: 10,
    });

    setTodayHistory((prev: any) => [
      ...prev,
      ...sortDatesInAscendingOrder(
        response.filter(
          (ele: any) =>
            new Date(ele.createdAt).getTime() >= startOfToday &&
            new Date(ele.createdAt).getTime() <= endOfToday
        ),
        "createdAt"
      ),
    ]);
    setYesterdayHistory((prev: any) => [
      ...prev,
      ...sortDatesInAscendingOrder(
        response.filter(
          (ele: any) =>
            new Date(ele.createdAt).getTime() >= startOfYesterday &&
            new Date(ele.createdAt).getTime() <= endOfYesterday
        ),
        "createdAt"
      ),
    ]);
    setOneWeekAgoHistory((prev: any) => [
      ...prev,
      ...sortDatesInAscendingOrder(
        response.filter(
          (ele: any) =>
            new Date(ele.createdAt).getTime() <= yesterday &&
            new Date(ele.createdAt).getTime() >= startOfWeekAgo
        ),
        "createdAt"
      ),
    ]);
    setLastOneMonth((prev: any) => [
      ...prev,
      ...sortDatesInAscendingOrder(
        response.filter(
          (ele: any) => new Date(ele.createdAt).getTime() <= startOfWeekAgo
        ),
        "createdAt"
      ),
    ]);
  }

  /**
   * Save history
   */
  const [currentHistoryId, setCurrentHistoryId] = useLocalStorage(
    "_trip_trove_current_history_id",
    ""
  );
  const [callSaveHistoryAPIFlag, setCallSaveHistoryAPIFlag] = useState(false);
  async function saveHistory(chatDataArgs: any, method: "POST" | "PUT") {
    if (!chatDataArgs || !userDetails?.id) return;
    let historyData: any = {
      userId: userDetails?.id || "",
      history: chatDataArgs,
    };
    if (currentHistoryId && method === "PUT")
      historyData = {
        id: currentHistoryId || "",
        userId: userDetails?.id || "",
        history: chatDataArgs,
      };
    if (userDetails?.id) {
      const response = await addHistoryAPI(historyData, method);
      setCurrentHistoryId(response?.data?.id);
    }
  }

  const value = {
    hasMoreHistory,
    historyPaginationLoaderRef,
    getHistory,
    isLoading,
    todayHistory,
    yesterdayHistory,
    oneWeekAgoHistory,
    lastOneMonth,
    saveHistory,
    callSaveHistoryAPIFlag,
    setCallSaveHistoryAPIFlag,
  };
  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};
export const useHistoryContext = () => useContext(HistoryContext);
