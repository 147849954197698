import React from "react";
import styles from "./flightTabsAccordion.module.scss";
import CustomAccordion from "../../../reusableComponent/customAccordion/CustomAccordion";
import {
  getDayOfWeek,
  getTimeDifference,
  getTwelveHourTimeFormat,
  getMonthDate,
  getYearYYYY,
} from "../../../../utils/dateUtils";
import { expandIcon, planeDepartureRegular } from "../../../../constant";
import FlightDetails from "../../../booking/flightDetails/flightDetails";
import { Button } from "@mui/material";
import ArrivalOnDifferentDate from "../../../reusableComponent/arrivalOnDifferentDate/ArrivalOnDifferentDate";

export default function FlightTabsAccordion({
  flightData,
  index,
}: {
  flightData?: any;
  index: number;
}) {
  const [showAllSteps, setShowAllSteps] = React.useState(false);
  const handleViewDetails = () => {
    // if (showAllSteps && onCloseStepperFunc) onCloseStepperFunc();
    // if (!showAllSteps && onOpenStepperFunc) onOpenStepperFunc();
    setShowAllSteps(!showAllSteps);
    console.log(showAllSteps);
  };

  return (
    <div className={styles.main}>
      <div className={styles.flightAccord}>
        <CustomAccordion
          className={styles.customFlightStyles}
          summaryClass={styles.summaryFlightClass}
          accordianDetailsClass={styles.accordFlightDetailsClass}
          defaultExpanded={true}
          hideExpandIcon={false}
          onChange={(_e, isExpanded) => {
            // if (isExpanded) if (handleExpandGAEvent) handleExpandGAEvent();
            // if (!isExpanded) if (handleCloseGAEvent) handleCloseGAEvent();
          }}
          headingComponent={
            <div className={styles.headingFlightComp} onClick={() => {}}>
              <div className={styles.accordTitleCard}>
                <p className={styles.dayAndCity}>
                  {getDayOfWeek(flightData?.departure?.date)}{" "}
                  {getMonthDate(flightData?.departure?.date)}
                </p>

                <p className={styles.accordDesc}>
                  {`${flightData?.departure?.airportName} (${flightData?.departure?.airport})`}{" "}
                  -{" "}
                  {`${flightData?.arrival?.airportName} (${flightData?.arrival?.airport})`}
                </p>
              </div>
              <img
                src={
                  // i.accordType === "flight"
                  //   ?
                  planeDepartureRegular
                  // : personWalkingLuggage
                }
                alt="plane"
                className={styles.planeDepartureIcon}
              />
            </div>
          }
          bodyComponent={
            <div className={styles.stepper}>
              <section className={styles.stepperDetails}>
                {/* <Stepper
                  // steps={i.stepsData}
                  // initialStep={0}
                  // finalStep={i.stepsData.length - 1}
                  steps={flightData?.segments}
                  stops={flightData?.stops}
                  initialStep={0}
                  finalStep={flightData?.length - 1}
                  flightImage={flightImage}
                /> */}

                <FlightStepperElement
                  flightData={flightData?.departure}
                  showAllSteps={showAllSteps}
                  initialStep={true}
                  handleViewDetails={handleViewDetails}
                  airline={flightData?.airline}
                  flightType="Departure"
                  date={flightData?.departure?.date}
                  airportCode={flightData?.departure?.airport}
                  airportName={flightData?.departure?.airportName}
                />
                {showAllSteps &&
                  flightData?.stops &&
                  flightData?.stops?.map((ele: any, index: number) => (
                    <div key={index} className={styles.stopsDetails}>
                      <FlightStepperElement
                        flightData={ele}
                        showAllSteps={showAllSteps}
                        initialStep={false}
                        airline={flightData?.airline}
                        flightType="Layover"
                        departureDate={
                          index === 0
                            ? flightData?.departure?.date
                            : flightData?.stops[index - 1]?.departureDate
                        }
                        date={ele?.arrivalDate}
                        airportCode={ele?.airport}
                        airportName={ele?.airportName}
                      />
                      <FlightStepperElement
                        flightData={ele}
                        showAllSteps={showAllSteps}
                        initialStep={false}
                        airline={flightData?.airline}
                        flightType="Departure"
                        date={ele?.departureDate}
                        airportCode={ele?.airport}
                        airportName={ele?.airportName}
                      />
                    </div>
                  ))}
                <FlightStepperElement
                  flightData={flightData?.arrival}
                  showAllSteps={showAllSteps}
                  initialStep={false}
                  airline={flightData?.airline}
                  flightType="Arrival"
                  lastStop={true}
                  departureDate={
                    flightData?.stops?.length > 0
                      ? flightData?.stops[flightData?.stops?.length - 1]
                          ?.departureDate
                      : ""
                  }
                  date={flightData?.arrival?.date}
                  airportCode={flightData?.arrival?.airport}
                  airportName={flightData?.arrival?.airportName}
                />
              </section>
              <div className={styles.divider} />
            </div>
          }
          zeroMarginOnExpand
          borderBottom
        />
      </div>
    </div>
  );
}

function FlightStepperElement({
  flightData,
  showAllSteps,
  initialStep,
  handleViewDetails,
  airline,
  flightType,
  lastStop = false,
  departureFromLayover = false,
  departureDate,
  date,
  airportCode,
  airportName,
}: {
  flightData: any;
  showAllSteps: boolean;
  initialStep: boolean;
  handleViewDetails?: any;
  airline?: any;
  flightType?: string;
  lastStop?: boolean;
  departureFromLayover?: boolean;
  departureDate?: string;
  date?: any;
  airportCode?: string;
  airportName?: string;
}) {
  function addHHMM(time: string) {
    if (typeof time !== "string" || time.split(":").length < 2) return "";
    const splitTime = time.split(":");
    return splitTime[0] + "h " + splitTime[1] + "m";
  }

  function checkIfArrivalIsNextDate(timestamp1: string, timestamp2: string) {
    if (!timestamp1 || !timestamp2) return false;
    const date1 = timestamp1.split("-");
    const date2 = timestamp2.split("-");
    return !(
      date1[0] === date2[0] &&
      date1[1] === date2[1] &&
      date1[2].substring(0, 2) === date2[2].substring(0, 2)
    );
  }

  return (
    <div className={styles.step}>
      <div className={styles.circle}></div>
      <div className={styles.stepContent}>
        <p className={styles.title}>
          <span className={styles.airportCode}>
            {airportCode}{" "}
            {airportName && (
              <span className={styles.airportName}>
                &nbsp; <b>|</b> &nbsp; {airportName}
              </span>
            )}
          </span>
        </p>
        <div className={styles.arrivalDateContainer}>
          {date && (
            <p
              className={`${checkIfArrivalIsNextDate(departureDate || "", date) ? styles.delayDescription : styles.description}`}
            >
              {getDayOfWeek(date, "long")}
              {", "}
              {getMonthDate(date)}
              {", "}
              {getYearYYYY(date)}
              {/* " | " +
             getTwelveHourTimeFormat(flightData?.date) */}
            </p>
          )}
          {checkIfArrivalIsNextDate(departureDate || "", date) && (
            <ArrivalOnDifferentDate />
          )}
        </div>
        {initialStep && (
          <Button
            endIcon={
              <img
                src={expandIcon}
                alt=""
                className={`${showAllSteps ? styles.hideIcon : styles.viewIcon}`}
              />
            }
            className={styles.viewDetailsButton}
            onClick={() => {
              handleViewDetails();
            }}
          >
            {showAllSteps ? "Collapse" : "Details"}
          </Button>
        )}
        {showAllSteps && (
          <div className={styles.card}>
            {flightType === "Departure" && (
              <div className={styles.subCard}>
                <FlightDetails
                  title={airline?.name}
                  subTitle={`${airline?.code && flightData?.flightNumber ? "Flight " + airline?.code + flightData?.flightNumber : ""}`}
                  description=""
                  imgUrl={airline?.image}
                />
                <FlightDetails
                  title={flightType || ""}
                  subTitle={""}
                  description={
                    <>
                      {getTwelveHourTimeFormat(date) && (
                        <>
                          {getTwelveHourTimeFormat(date)}
                          {!lastStop && (
                            <span>
                              &nbsp;&nbsp;<b> | </b> &nbsp;&nbsp;
                            </span>
                          )}
                        </>
                      )}
                      {(addHHMM(flightData?.flyDuration) !== "" ||
                        addHHMM(flightData?.layover) !== "") && (
                        <>
                          {departureFromLayover
                            ? "Flight time: "
                            : "Flight time: "}
                          {departureFromLayover
                            ? ""
                            : (!lastStop && addHHMM(flightData?.flyDuration)) ||
                              addHHMM(flightData?.layover) ||
                              ""}
                        </>
                      )}
                    </>
                  }
                  imgUrl={""}
                />
                {/* ${getTimeDifference(flightData?.departureDate, flightData?.arrivalDate)} */}
                {/* <FlightDetails
                              title={"Seats"}
                              subTitle={""}
                              description={""}
                              imgUrl={""}
                            /> */}
              </div>
            )}
            {flightType === "Layover" && (
              <div className={styles.subCard}>
                <FlightDetails
                  title={"Arrival"}
                  subTitle={""}
                  // description={getTwelveHourTimeFormat(flightData?.date)}
                  description={getTwelveHourTimeFormat(date)}
                  imgUrl={""}
                />
                <FlightDetails
                  title={flightType || ""}
                  subTitle={""}
                  // description={`${getTwelveHourTimeFormat(flightData?.date) && getTwelveHourTimeFormat(flightData?.date) + " | "} Flight time: ${flightData?.flyDuration || flightData?.layover}`}
                  description={
                    // addHHMM(flightData?.flyDuration) ||
                    addHHMM(flightData?.layover) || ""
                  }
                  imgUrl={""}
                />
              </div>
            )}

            {flightType === "Arrival" && (
              <div className={styles.subCard}>
                {!lastStop && (
                  <FlightDetails
                    title={flightType}
                    subTitle={`${airline?.code && flightData?.flightNumber ? "Flight " + airline?.code + flightData?.flightNumber : ""}`}
                    description=""
                    imgUrl={airline?.image}
                  />
                )}
                <FlightDetails
                  title={flightType || ""}
                  subTitle={""}
                  description={
                    <>
                      {flightType === "Arrival" && !lastStop
                        ? ""
                        : getTwelveHourTimeFormat(date) && (
                            <>
                              {getTwelveHourTimeFormat(date)}
                              {!lastStop && (
                                <span>
                                  &nbsp;&nbsp;<b> | </b> &nbsp;&nbsp;
                                </span>
                              )}
                            </>
                          )}
                      {(addHHMM(flightData?.flyDuration) !== "" ||
                        addHHMM(flightData?.layover) !== "") && (
                        <>
                          {flightType === "Arrival" && lastStop
                            ? ""
                            : "Flight time: "}
                          {departureFromLayover
                            ? ""
                            : (!lastStop && addHHMM(flightData?.flyDuration)) ||
                              addHHMM(flightData?.layover) ||
                              ""}
                        </>
                      )}
                    </>
                  }
                  imgUrl={""}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {flightType !== "Arrival" && (
        <div
          className={`${flightType === "Layover" ? styles.dashLine : styles.line}`}
        ></div>
      )}
    </div>
  );
}

/**
 * 
 *     {flightType === "Layover" ? (
              <div className={styles.subCard}>
                <FlightDetails
                  title={"Arrival"}
                  subTitle={""}
                  // description={getTwelveHourTimeFormat(flightData?.date)}
                  description={getTwelveHourTimeFormat(date)}
                  imgUrl={""}
                />
                <FlightDetails
                  title={flightType || ""}
                  subTitle={""}
                  // description={`${getTwelveHourTimeFormat(flightData?.date) && getTwelveHourTimeFormat(flightData?.date) + " | "} Flight time: ${flightData?.flyDuration || flightData?.layover}`}
                  description={
                    // addHHMM(flightData?.flyDuration) ||
                    addHHMM(flightData?.layover) || ""
                  }
                  imgUrl={""}
                />
              </div>
            ) : (
              <div className={styles.subCard}>
                {flightType !== "Arrival" && (
                  <FlightDetails
                    title={airline?.name}
                    subTitle={`${airline?.code && flightData?.flightNumber ? "Flight " + airline?.code + flightData?.flightNumber : ""}`}
                    description=""
                    imgUrl={airline?.image}
                  />
                )}
                <FlightDetails
                  title={flightType || ""}
                  subTitle={""}
                  description={
                    <>
                      {flightType === "Arrival" && !lastStop
                        ? ""
                        : getTwelveHourTimeFormat(date) && (
                            <>
                              {getTwelveHourTimeFormat(date)}
                              {!lastStop && (
                                <span>
                                  &nbsp;&nbsp;<b> | </b> &nbsp;&nbsp;
                                </span>
                              )}
                            </>
                          )}
                      {(addHHMM(flightData?.flyDuration) !== "" ||
                        addHHMM(flightData?.layover) !== "") && (
                        <>
                          {flightType !== "Arrival" && departureFromLayover
                            ? "Flight time: "
                            : "Flight time: "}
                          {departureFromLayover
                            ? ""
                            : (!lastStop && addHHMM(flightData?.flyDuration)) ||
                              addHHMM(flightData?.layover) ||
                              ""}
                        </>
                      )}
                    </>
                  }
                  imgUrl={""}
                />
 * 
 */
