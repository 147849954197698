import { Button, Grid } from "@mui/material";
import {
  modifyEditIcon,
  FlightCancelIcon,
  personWalkingLuggage,
  creditCardIcon,
} from "../../../constant";
import styles from "./flightsTabs.module.scss";
import CustomAccordion from "../../reusableComponent/customAccordion/CustomAccordion";
import React from "react";
import TravelerDetails from "../travelerDetails/travelerDetails";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import FlightTabsAccordion from "./flightTabsAccordion/FlightTabsAccordion";

function FlightsTabs({
  flightDetails,
  travellersInfo,
  flightPayment,
}: {
  flightDetails: any;
  travellersInfo: any;
  flightPayment: any;
}): JSX.Element {
  console.log(flightDetails);
  const inboundFlight =
    flightDetails &&
    flightDetails?.sectors?.length > 0 &&
    flightDetails?.sectors[0]?.inbound?.length > 0 &&
    flightDetails?.sectors[0]?.inbound;
  const outboundFlight =
    flightDetails &&
    flightDetails?.sectors?.length > 0 &&
    flightDetails?.sectors[0]?.outbound?.length > 0 &&
    flightDetails?.sectors[0]?.outbound;

  // const travelerInfoData =
  //   travellersInfo?.travelersDetails || travellersInfo?.travelersDetailsList;
  console.log({ travellersInfo });

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <header className={styles.header}>
          <Button
            className={styles.headerBtn}
            endIcon={
              <img
                src={FlightCancelIcon}
                alt="img"
                className={styles.flightIcon}
              />
            }
            onClick={() =>
              triggerGAEvent(
                GA_LABEL_ENUMS.trip_details_flights_cancel_option_click
              )
            }
          >
            Cancel Options
          </Button>
          <Button
            className={styles.headerBtn}
            endIcon={
              <img src={modifyEditIcon} alt="img" className={styles.editIcon} />
            }
            onClick={() =>
              triggerGAEvent(
                GA_LABEL_ENUMS.trip_details_flights_change_flight_click
              )
            }
          >
            Change Flight
          </Button>
        </header>

        {/* FLights */}
        <div className={styles.flightAccord}>
          {flightDetails &&
            flightDetails?.length &&
            flightDetails?.map((ele: any, index: number) => (
              <React.Fragment key={index}>
                <FlightTabsAccordion flightData={ele} index={index} />
              </React.Fragment>
            ))}

          {/* {flightDetails ? (
            <>
              {outboundFlight &&
                outboundFlight?.map((ele: any, index: number) => (
                  <div key={index}>
                    <FlightDetails
                      hideExpandIcon={false}
                      flightData={ele}
                      flightImage={""}
                      handleExpandGAEvent={() =>
                        triggerGAEvent(
                          GA_LABEL_ENUMS.trip_details_flight_details_accordion_click
                        )
                      }
                      handleCloseGAEvent={() =>
                        triggerGAEvent(
                          GA_LABEL_ENUMS.trip_details_flight_collapse_accordion_click
                        )
                      }
                    />
                  </div>
                ))}
              {inboundFlight &&
                inboundFlight?.map((ele: any, index: number) => (
                  <div key={index}>
                    <FlightDetails
                      hideExpandIcon={false}
                      flightData={ele}
                      flightImage={""}
                      handleExpandGAEvent={() =>
                        triggerGAEvent(
                          GA_LABEL_ENUMS.trip_details_flight_details_accordion_click
                        )
                      }
                      handleCloseGAEvent={() =>
                        triggerGAEvent(
                          GA_LABEL_ENUMS.trip_details_flight_collapse_accordion_click
                        )
                      }
                    />
                  </div>
                ))}
            </>
          ) : (
            <p className={styles.noFlight}>No flight booked</p>
          )} */}

          {/* Traveler info */}
          {/* {BookedFlightsInfo.map((i, index) => ( */}
          <CustomAccordion
            className={styles.customFlightStyles}
            summaryClass={styles.summaryFlightClass}
            accordianDetailsClass={styles.accordFlightDetailsClass}
            defaultExpanded={false}
            headingComponent={
              <div
                className={styles.headingFlightComp}
                // key={index}
                onClick={() =>
                  triggerGAEvent(
                    GA_LABEL_ENUMS.trip_details_flight_traveler_accordion_click
                  )
                }
              >
                <div className={styles.accordTitleCard}>
                  <p className={styles.accordDesc}>Travelers</p>
                  {/* {i.passengers && ( */}
                  <p className={styles.traveler}>
                    {/* {getPassengerCount(
                      travellersInfo?.adultsCount
                        ? Number(travellersInfo?.adultsCount)
                        : 0,
                      travellersInfo?.childrenCount
                        ? Number(travellersInfo?.childrenCount)
                        : 0,
                      travellersInfo?.seniorsCount
                        ? Number(travellersInfo?.seniorsCount)
                        : 0
                    )} */}
                  </p>
                  {/* )} */}
                </div>
                {/* {i.accordType && ( */}
                <img
                  src={
                    // i.accordType === "flight"
                    //   ? planeDepartureRegular
                    // :
                    personWalkingLuggage
                  }
                  alt="plane"
                  className={styles.planeDepartureIcon}
                />
                {/* )} */}
              </div>
            }
            bodyComponent={
              <React.Fragment>
                {travellersInfo &&
                  travellersInfo?.length &&
                  travellersInfo?.map((ele: any, index: number) => (
                    <TravelerDetails
                      count={index}
                      name={ele?.firstName + " " + ele?.lastName}
                      phone={
                        ele?.countryCode &&
                        ele?.phoneNumber &&
                        ele?.countryCode + " " + ele?.phoneNumber
                      }
                      gender={ele?.title === "Mr" ? "Male" : "Female"}
                      meal={ele?.meals[0]}
                      email={ele.email}
                      skyMiles={ele.FF}
                      specialNeeds={
                        // Object?.keys(ele?.specialAssistance)?.filter(
                        //   (key) => ele?.selectedOptions[key]
                        // ) || []
                        // ele?.operationalReference?.specialAssistance || []
                        ele?.operationalReference?.specialAssistance
                      }
                    />
                  ))}
              </React.Fragment>
            }
            zeroMarginOnExpand
            borderBottom
          />
          {/* ))} */}

          {/* Flight payment details */}
          {flightPayment && (
            <CustomAccordion
              className={styles.customFlightStyles}
              summaryClass={styles.summaryFlightClass}
              accordianDetailsClass={styles.accordFlightDetailsClass}
              defaultExpanded={false}
              headingComponent={
                <div
                  className={styles.headingFlightComp}
                  onClick={() =>
                    triggerGAEvent(
                      GA_LABEL_ENUMS.trip_details_flight_traveler_accordion_click
                    )
                  }
                >
                  <div className={styles.accordTitleCard}>
                    <p className={styles.accordDesc}>Payments</p>
                  </div>
                  <img
                    src={creditCardIcon}
                    alt="plane"
                    className={styles.planeDepartureIcon}
                  />
                </div>
              }
              bodyComponent={
                <Grid container pt={"20px"}>
                  <Grid xs={12} className={styles.grid}>
                    <h1>Flight price</h1>
                  </Grid>
                  {flightPayment?.passengerCount !== null && flightPayment?.base !== null && (
                    <>
                      <Grid xs={4} className={styles.grid}>
                        <h2>
                          {flightPayment?.passengerCount} &nbsp;
                          {flightPayment?.passengerCount > 1
                            ? "Travelers"
                            : "Traveler"}
                        </h2>
                      </Grid>
                      <Grid xs={8} className={styles.grid}>
                        <h1>${flightPayment?.base?.toFixed(2)}</h1>
                      </Grid>
                    </>
                  )}

                  {flightPayment?.taxes !== null && (
                    <>
                      <Grid xs={4} className={styles.grid}>
                        <h2>Taxes</h2>
                      </Grid>
                      <Grid xs={8} className={styles.grid}>
                        <h1>${flightPayment?.taxes?.toFixed(2)}</h1>
                      </Grid>
                    </>
                  )}

                  {flightPayment?.total !== null&& (
                    <>
                      <Grid xs={4} className={styles.grid}>
                        <h2>Total</h2>
                      </Grid>
                      <Grid xs={8} className={styles.grid}>
                        <h1>${flightPayment?.total?.toFixed(2)}</h1>
                        {/* <p>{`${"(" + "Paid - Mastercard*5478" + ")"}`}</p> */}
                      </Grid>
                    </>
                  )}
                </Grid>
              }
              zeroMarginOnExpand
              borderBottom
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FlightsTabs;
