import React from "react";
import styles from "./hotels.module.scss";
import { creditCardIcon, modifyEditIcon, redBedIcon } from "../../../constant";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BookedHotelAccordion from "../../reusableComponent/bookedHotelCardList/BookedHotelAccordion";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import CustomAccordion from "../../reusableComponent/customAccordion/CustomAccordion";
import { getDayOfWeek, getMonthDate } from "../../../utils/dateUtils";

export default function HotelsTabs({
  hotelSummary,
  hotelPayment,
}: {
  hotelSummary?: any;
  hotelPayment?: any;
}) {
  const navigate = useNavigate();
  console.log("response?.data.hotel", hotelPayment);

  return (
    <>
      <header className={styles.header}>
        <Button
          className={styles.headerBtn}
          onClick={() =>
            triggerGAEvent(
              GA_LABEL_ENUMS.trip_details_hotel_cancel_option_click
            )
          }
          endIcon={
            <img src={redBedIcon} alt="img" className={styles.flightIcon} />
          }
        >
          Cancel Options
        </Button>
        <Button
          onClick={() =>
            triggerGAEvent(GA_LABEL_ENUMS.trip_details_hotel_change_hotel_click)
          }
          className={styles.headerBtn}
          endIcon={
            <img src={modifyEditIcon} alt="img" className={styles.editIcon} />
          }
        >
          Change Hotel
        </Button>
      </header>
      <div className={styles.flightAccord}>
        {hotelSummary?.length > 0 ? (
          hotelSummary?.map((hotel: any, index: number) => {
            // const {
            //   checkIn = "",
            //   checkOut = "",
            //   city = "",
            //   hotelInformation = {},
            //   roomInfo = {},
            // } = ele;
            // const { stars, name, hotelMainImage, address, hotelId } =
            //   hotelInformation;
            // const { noOfRooms, bedInformation = [] } = roomInfo;
            const {
              // hotelMainImage,
              // stars,
              // name,
              // address,
              hotelInformation,
              amenities,
              totalPrice,
              cancellationPolicy,
              hotelBookId,
              checkIn = "",
              checkOut = "",
              city = "",
            } = hotel;
            const {
              stars,
              name,
              hotelMainImage,
              address,
              hotelId,
              rating,
              reviewCount,
              reviewHeading,
            } = hotelInformation;
            return (
              <BookedHotelAccordion
                checkIn={checkIn}
                checkOut={checkOut}
                // stars={stars}
                // name={name}
                // hotelMainImage={hotelMainImage}
                // address={address}
                // noOfRooms={noOfRooms}
                // bedInformation={bedInformation}
                // city={city}
                // hotelId={hotelId}
                id={index}
                hotelMainImage={hotelMainImage}
                star={stars}
                hotelName={name}
                hotelLocation={address}
                hotelRating={rating}
                reviewCount={reviewCount}
                cost={totalPrice?.price}
                cancellation={cancellationPolicy}
                Amenity={amenities}
                hotelList={true}
                discount={""}
                discountCost={""}
                taxesIncluded={totalPrice?.taxesIncluded}
                reviewHeading={reviewHeading}
                hotelId={hotelId}
                hotelDetails={hotel}
                city={city}
                hotelAccordionClick={() =>
                  triggerGAEvent(
                    GA_LABEL_ENUMS.trip_details_hotel_accordion_click
                  )
                }
                hotelViewDetailsClick={() =>
                  triggerGAEvent(
                    GA_LABEL_ENUMS.trip_details_hotel_view_deatils_accordion_click
                  )
                }
              />
            );
          })
        ) : (
          <p className={styles.noHotel}>No hotel booked</p>
        )}

        {/* {hotelInformationArr.map((hotelInfo, index) => {
          const {
            checkInCheckOutDate = "",
            hotelName = "",
            hotelImage,
            hotelStar,
            place = "",
            hotelPlace = "",
            address = "",
            roomDetails = [],
          } = hotelInfo;
          return (
            <CustomAccordion
              className={styles.customFlightStyles}
              summaryClass={styles.summaryFlightClass}
              accordianDetailsClass={styles.accordFlightDetailsClass}
              defaultExpanded={false}
              headingComponent={
                <div className={styles.headingFlightComp} key={index}>
                  <div className={styles.accordTitleCard}>
                    {checkInCheckOutDate && (
                      <p className={styles.dayAndCity}>{checkInCheckOutDate}</p>
                    )}
                    {hotelPlace && (
                      <p className={styles.accordDesc}>{hotelPlace}</p>
                    )}
                  </div>
                  {bedFontLight && (
                    <img
                      src={bedFontLight}
                      alt="plane"
                      className={styles.planeDepartureIcon}
                    />
                  )}
                </div>
              }
              bodyComponent={
                <div className={styles.main}>
                  <div className={styles.twoSectionsContainer}>
                    <img
                      src={hotelImage || hotelRoomIcon}
                      alt="hotel"
                      className={`${styles.hotelImg}`}
                    />
                    <section className={styles.contentCard}>
                      <div className={styles.starContainer}>
                        <img
                          className={styles.starPropertyImg}
                          src={propertyStarBlue}
                          alt="star icon"
                        />
                        <span className={styles.star}>
                          {hotelStar} star property
                        </span>
                      </div>
                      <p className={styles.hotelName}>{hotelName}</p>
                      <p className={styles.location}>
                        {place} &nbsp; | &nbsp; {address}
                      </p>
                      {roomDetails.length > 0 && (
                        <div className={styles.roomDetailsContainer}>
                          {roomDetails.map((room, idx) => {
                            const { bedCount, bedSize, roomCount } = room;
                            return (
                              <div className={styles.room} key={idx}>
                                {roomCount} Room -{" "}
                                {bedCount > 1 ? bedCount : ""} {bedSize}{" "}
                                {bedCount > 1 ? "Beds" : "Bed"}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </section>
                  </div>
                  <div className={styles.card3}>
                    <Button
                      className={styles.flightBtn}
                      onClick={() => navigate(ROUTES.HotelDetails)}
                    >
                      <div className={styles.iconAndText}>
                        <img
                          src={eyeLight}
                          alt="icon"
                          className={styles.expIcon}
                        />
                        <span className={styles.viewDetails}>
                          VIEW <br /> DETAILS
                        </span>
                      </div>
                    </Button>
                  </div>
                </div>
              }
              zeroMarginOnExpand
              borderBottom
            />
          );
        })} */}
        {/* <div className={styles.flightAccords}>
          {BookedFlightsInfo.filter(
            (item) => item.accordType === "traveler"
          ).map((i, index) => (
            <CustomAccordion
              className={styles.customFlightStyles}
              summaryClass={styles.summaryFlightClass}
              accordianDetailsClass={styles.accordFlightDetailsClass}
              defaultExpanded={false}
              headingComponent={
                <div className={styles.headingFlightComp} key={index}>
                  <div className={styles.accordTitleCard}>
                    {i.date && <p className={styles.dayAndCity}>{i.date}</p>}
                    {i.flightFrom && (
                      <p className={styles.accordDesc}>
                        {i.flightFrom} - {i.fightTo}
                      </p>
                    )}
                    {i.title && <p className={styles.accordDesc}>{i.title}</p>}
                    {i.passengers && (
                      <p className={styles.traveler}>
                        {i.passengers.adult} {","}
                        {i.passengers.child}
                        {i.passengers.senior}
                      </p>
                    )}
                  </div>
                  {i.accordType && (
                    <img
                      src={personWalkingLuggage}
                      alt="plane"
                      className={styles.planeDepartureIcon}
                    />
                  )}
                </div>
              }
              bodyComponent={
                <React.Fragment>
                  {i.traveler &&
                    i.traveler.map((i: any, index: any) => (
                      <TravelerDetails
                        count={index}
                        name={i.name}
                        phone={i.phoneNumber}
                        gender={i.gender}
                        meal={i.meal}
                        email={i.email}
                        skyMiles={i.FF}
                        specialNeeds={i.specialNeeds}
                      />
                    ))}
                </React.Fragment>
              }
              zeroMarginOnExpand
              borderBottom
            />
          ))}
        </div> */}

        {/* Payment Details */}
        {hotelPayment && hotelPayment?.length > 0 && (
          <CustomAccordion
            className={styles.customFlightStyles}
            summaryClass={styles.summaryFlightClass}
            accordianDetailsClass={styles.accordFlightDetailsClass}
            defaultExpanded={false}
            headingComponent={
              <div
                className={styles.headingFlightComp}
                onClick={() =>
                  triggerGAEvent(
                    GA_LABEL_ENUMS.trip_details_flight_traveler_accordion_click
                  )
                }
              >
                <div className={styles.accordTitleCard}>
                  <p className={styles.accordDesc}>Payments</p>
                </div>
                <img
                  src={creditCardIcon}
                  alt="plane"
                  className={styles.planeDepartureIcon}
                />
              </div>
            }
            bodyComponent={hotelPayment?.map((ele: any, index: number) => (
              <div
                key={index}
                className={styles.paymentDetailsContainer}
                style={{
                  borderBottom:
                    index !== hotelPayment?.length - 1
                      ? "1px solid #dddcdc"
                      : "none",
                }}
              >
                <div className={styles.paymentHeaderContainer}>
                  <p>
                    {getDayOfWeek(ele?.checkInDate) +
                      " " +
                      getMonthDate(ele?.checkInDate)}{" "}
                    -{" "}
                    {getDayOfWeek(ele?.checkOutDate) +
                      " " +
                      getMonthDate(ele?.checkOutDate)}{" "}
                    <span>|</span> {ele?.city}
                  </p>
                  <h1>{ele?.hotelName}</h1>
                </div>
                <Grid container>
                  <Grid xs={12} className={styles.grid}>
                    <h1>Room price</h1>
                  </Grid>
                  {ele?.roomCount !== null && ele?.base !== null && (
                    <>
                      <Grid xs={4} className={styles.grid}>
                        <p>
                          {" "}
                          {ele?.roomCount} &nbsp;
                          {ele?.roomCount > 1 ? "Rooms" : "Room"}
                        </p>
                      </Grid>
                      <Grid xs={8} className={styles.grid}>
                        <h2>${ele?.base?.toFixed(2)}</h2>
                      </Grid>
                    </>
                  )}

                  {ele?.taxes !== null && (
                    <>
                      <Grid xs={4} className={styles.grid}>
                        <p>Taxes</p>
                      </Grid>
                      <Grid xs={8} className={styles.grid}>
                        <h2>${ele?.taxes?.toFixed(2)}</h2>
                      </Grid>
                    </>
                  )}

                  {ele?.total !== null && (
                    <>
                      <Grid xs={4} className={styles.grid}>
                        <p>Total</p>
                      </Grid>
                      <Grid xs={8} className={styles.grid}>
                        <h2>${ele?.total?.toFixed(2)}</h2>
                        {/* <p>{`${"(" + "Paid - Mastercard*5478" + ")"}`}</p> */}
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            ))}
            zeroMarginOnExpand
            borderBottom
          />
        )}
      </div>
    </>
  );
}
