import { useContext, useEffect, useState } from "react";
import { calendarRangeDark } from "../../../../constant";
import { BookingDetails } from "../../../../types";
import styles from "./range.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
// import DateRangePickerPopover from "../../bookingPopover/dateRangePickerPopover/DateRangePickerPopover";
import SingleDatePickerPopover from "../../bookingPopover/singleDatePickerPopover/SingleDatePickerPopver";
import FormatInputToUTC from "../../../../hooks/utcDateFormater";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";

interface iProps {
  className?: string;
  startDate: string;
  endDate: string;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  handleUpdateData: () => void;
}

const DateRangePicker: React.FC<iProps> = ({
  className = "",
  bookingDetails,
  setBookingDetails,
  handleUpdateData,
}) => {
  const state = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = state;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [dateRangeValues, setDateRangeValues] = useState<string | null>(null);
  // const [isIdle, setIsIdle] = useState(false);
  // const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // const addDays = (date: Date, days: number): Date => {
  //   const result = new Date(date);
  //   result.setUTCDate(result.getUTCDate() + days);
  //   return result;
  // };

  const addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const handleDateChange = (value: Date | null, days: number) => {
    if (value) {
      const startDate = value;
      const endDate = addDays(startDate, (days ?? 1) - 1);
      const formattedStartDate = formatDate(startDate);

      const formattedEndDate = formatDate(endDate);
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        travelFromDate: formattedStartDate,
        travelToDate: formattedEndDate,
        update: true,
      }));
    } else {
      // console.log("No valid date selected");
    }
  };

  useEffect(() => {
    handleUpdateData();
  }, [bookingDetails.travelToDate]);

  const handleApplyButton = () => {
    triggerGAEvent(
      GA_LABEL_ENUMS.booking_travel_date_apply_click
    );
    const { noOfDays, numberOfdays } = selectedChatData;
    const days = noOfDays || numberOfdays;
    if (dateRangeValues && days) {
      const date = new Date(dateRangeValues);
      handleDateChange(date, days);
      // handleUpdateData();
    } else {
      console.error("No date selected");
    }
  };
  // useEffect(() => {
  //   const travelFromDate = bookingDetails?.travelFromDate;
  //   if (travelFromDate) {
  //     const formattedDate =
  //       new Date(travelFromDate).toISOString().split("T")[0] + "T00:00:00";
  //     setDateRangeValues(formattedDate);
  //   } else {
  //     console.warn("Invalid travelFromDate:", travelFromDate);
  //   }
  // }, [bookingDetails.travelFromDate]);

  useEffect(() => {
    const travelFromDate = bookingDetails?.travelFromDate;

    if (!travelFromDate) {
      const today = new Date();
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
      const formattedNextMonthDate = formatDate(nextMonth);
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        travelFromDate: formattedNextMonthDate,
        update: true,
      }));

      setDateRangeValues(`${formattedNextMonthDate}T00:00:00`);
    } else {
      const formattedDate =
        new Date(travelFromDate).toISOString().split("T")[0] + "T00:00:00";
      setDateRangeValues(formattedDate);
    }
  }, [bookingDetails.travelFromDate, setBookingDetails]);

  useEffect(() => {
    const updateStartDate = setTimeout(() => {
      const { noOfDays, numberOfdays } = selectedChatData;
      const days = noOfDays || numberOfdays;
      if (bookingDetails.travelFromDate && days) {
        const date = new Date(bookingDetails.travelFromDate);
        const formattedDate = date.toISOString().split("T")[0] + "T00:00:00";
        handleDateChange(new Date(formattedDate), days);
      }
    }, 3000);

    return () => clearTimeout(updateStartDate);
  }, []);

  // const resetTimeout = () => {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }
  //   timeoutRef.current = setTimeout(() => setIsIdle(true), 1200000);
  // };

  // useEffect(() => {
  //   const handleActivity = () => {
  //     setIsIdle(false);
  //     resetTimeout();
  //   };

  //   window.addEventListener("mousemove", handleActivity);
  //   window.addEventListener("keydown", handleActivity);

  //   resetTimeout();

  //   return () => {
  //     if (timeoutRef.current) {
  //       clearTimeout(timeoutRef.current);
  //     }
  //     window.removeEventListener("mousemove", handleActivity);
  //     window.removeEventListener("keydown", handleActivity);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isIdle) {
  //     handleUpdateData();
  //   }
  // }, [isIdle]);
  
  return (
    <div>
      <SingleDatePickerPopover
        value={dateRangeValues}
        setValue={setDateRangeValues}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        handleApplyButton={handleApplyButton}
        popoverTrigger={
          <div className={`${className} ${styles.main}`}>
            <p className={styles.label}>travel date</p>
            <div
              className={styles[isPopoverOpen ? "activeTravelBtn" : "btn"]}
              onClick={() => {
                triggerGAEvent(GA_LABEL_ENUMS.booking_downward_accordion_click);
              }}
            >
              <img
                src={calendarRangeDark}
                alt="icon"
                className={styles.calendarIcon}
              />
              {bookingDetails.travelFromDate ? (
                <p className={styles.btnName}>
                  {FormatInputToUTC(bookingDetails.travelFromDate)}
                </p>
              ) : (
                <p className={styles.btnName}></p>
              )}{" "}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default DateRangePicker;
